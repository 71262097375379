<template>
  <div class="header-container" :class="{ active: isActive }">
    <div class="header-box" :class="{'expand': isExpand}">
      <div class="logo">
        <img @click="linkTo('HOME')" src="../../assets/common/logo.png" alt="" />
      </div>
      <div class="menu-btn" @click="expand()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="menu">
        <div class="item" :class="{'active': currentMenu == item.value}" v-for="(item, index) in menus" :key="index">
          <span v-if="item.value == 'pricing'" @click="linkTo('PRICING')">{{ t(item.name) }}</span>
          <span v-else>{{ t(item.name) }}</span>
          <div class="sub-menu-box" v-if="item.name.indexOf('product') > -1">
            <div class="sub-menu">
              <div class="lf">
                <div class="title">{{ t("nav.sub_products") }}</div>
                <div class="content">
                  <div class="sub-item" @click="linkTo('CDP')">
                    <div class="sub-icon">
                      <img src="../../assets/common/cdp.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_cdp")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('CDS')">
                    <div class="sub-icon">
                      <img src="../../assets/common/cds.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_cds")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('CCM')">
                    <div class="sub-icon">
                      <img src="../../assets/common/ccm.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_ccm")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('IQ')">
                    <div class="sub-icon">
                      <img src="../../assets/common/iqplatform.png" alt="" />
                    </div>
                    <span>{{ t("nav.sub_iqplatform") }}</span>
                  </div>
                </div>
              </div>
              <div class="lf" v-if="false">
                <div class="title">{{ t("nav.sub_features") }}</div>
                <div class="content">
                  <div class="sub-item">
                    <div class="sub-icon">
                      <img src="../../assets/common/channel.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_channel")}}</span>
                  </div>
                  <div class="sub-item">
                    <div class="sub-icon">
                      <img src="../../assets/common/campaign.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_campaign")}}</span>
                  </div>
                  <div class="sub-item">
                    <div class="sub-icon">
                      <img src="../../assets/common/audience.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_audience")}}</span>
                  </div>
                  <div class="sub-item">
                    <div class="sub-icon">
                      <img src="../../assets/common/data.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_data")}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-menu-box" v-if="item.name.indexOf('company') > -1">
            <div class="sub-menu">
              <div class="content">
                  <div class="sub-item" @click="linkTo('ABOUT')">
                    <div class="sub-icon">
                      <img src="../../assets/common/about_us.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_about_us")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('NEWS')">
                    <div class="sub-icon">
                      <img src="../../assets/common/news.png" alt="" />
                    </div>
                    <span>{{ t("nav.sub_pres_media") }}</span>
                  </div>
                  <!-- <div class="sub-item" @click="linkTo('BLOG')">
                    <div class="sub-icon">
                      <img src="../../assets/common/blog.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_blog")}}</span>
                  </div> -->
                  <div class="sub-item" @click="linkTo('PARTNERS')">
                    <div class="sub-icon">
                      <img src="../../assets/common/partner.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_partners")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('CONTACT')">
                    <div class="sub-icon">
                      <img src="../../assets/common/contact.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_contact_us")}}</span>
                  </div>
                </div>
            </div>
          </div>
          <div class="sub-menu-box" v-if="item.name.indexOf('solutions') > -1">
            <div class="sub-menu">
              <div class="content">
                  <div class="sub-item" @click="linkTo('RETAIL')">
                    <div class="sub-icon">
                      <img src="../../assets/common/retail.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_retail")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('GAME')">
                    <div class="sub-icon">
                      <img src="../../assets/common/game.png" alt="" />
                    </div>
                    <span>{{ t("nav.sub_game") }}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('FINANCIAL')">
                    <div class="sub-icon">
                      <img src="../../assets/common/financial.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_financial_services")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('TRAVEL')">
                    <div class="sub-icon">
                      <img src="../../assets/common/travel.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_travel")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('MEDIA')">
                    <div class="sub-icon">
                      <img src="../../assets/common/media.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_media")}}</span>
                  </div>
                  <div class="sub-item" @click="linkTo('AUTOMOTIVE')">
                    <div class="sub-icon">
                      <img src="../../assets/common/automotive.png" alt="" />
                    </div>
                    <span>{{t("nav.sub_automotive")}}</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-btn">
        <a href="#" id="headerBtn">{{t("nav.btn")}}</a>
      </div>
      <div class="lang">
        <div class="current-lang">
          <img src="../../assets/common/lang.png" alt="" />
          <!-- <span>{{ currentLang }}</span> -->
        </div>
        <div class="changeLang">
          <div
            class="item"
            v-for="item in langList"
            :key="item.id"
            @click="changeLang(item)"
          >
            <span>{{ item.lang }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";
import router from '../../router/index.js'
export default {
  name: "Header",
  data() {
    return {
      isActive: false,
      isExpand: false,
      langList: [
        { lang: "English", id: 1, value: "en-US", path: '', name: '' },
        { lang: "中文", id: 0, value: "zh-CN", path: '/zh', name: 'zh' },
        { lang: "Korean", id: 2, value: "kr", path: '/kr', name: 'kr' },
      ],
    };
  },
  setup() {
    const { locale } = useI18n();
    const { t } = useI18n();
    const menus = ref([
        {
          name: "nav.product",
          value: 'products'
        },
        {
          name: "nav.pricing",
          value: "pricing"
        },
        {
          name: "nav.solutions",
          value: 'solutions'
        },
        {
          name: "nav.company",
          value: 'company'
        },
      ]) 
    let {proxy} = getCurrentInstance()
    // currentRoute = proxy.$route.meta.menu
    let locale_lang = location.pathname.indexOf('/zh') > -1 ? '中文' : location.pathname.indexOf('/kr') > -1 ? 'Korean' : 'English'
    let locale_value = location.pathname.indexOf('/zh') > -1 ? 'zh-CN' : location.pathname.indexOf('/kr') > -1 ? 'Korean' : 'en-US'
    let currentLang = ref("");
    currentLang.value = localStorage.getItem("current_lang") && JSON.parse(localStorage.getItem("current_lang")).lang || locale_lang;
    locale.value = localStorage.getItem("current_lang") && JSON.parse(localStorage.getItem("current_lang")).value || locale_value;
    currentLang.value = currentLang.value
    if (locale_value == 'zh-CN') {
      localStorage.setItem("current_lang", JSON.stringify({ lang: "中文", id: 0, value: "zh-CN", path: '/zh', name: 'zh' }))
    } else if (locale_value == 'en-US'){
      localStorage.setItem("current_lang", JSON.stringify({ lang: "English", id: 1, value: "en-US", path: '', name: '' }))
    } else {
      localStorage.setItem("current_lang", JSON.stringify({ lang: "Korean", id: 1, value: "kr", path: '/kr', name: 'kr' }))
    }
    const changeLang = (lang) => {
      localStorage.setItem("current_lang", JSON.stringify(lang))
      if (currentLang.value && currentLang.value == lang.lang) return
      let path = location.pathname.indexOf('/zh') > -1 ? location.pathname.split('/zh')[1] : location.pathname.indexOf('/kr') > -1 ?  location.pathname.split('/kr')[1] : location.pathname
      location.href = location.origin + lang.path + path
    };

    let currentMenu = ref('')
    currentMenu.value = proxy.$route.meta.menu
    onBeforeRouteUpdate(to => {
      currentMenu.value = to.meta.menu
    });
    return {
      t,
      menus,
      currentLang,
      changeLang,
      currentMenu
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      this.isActive = document.documentElement.scrollTop > 10 ? true : false;
    },
    linkTo(e) {
      let currentLang = JSON.parse(localStorage.getItem('current_lang'))
      this.$router.push({name: currentLang && currentLang.name ? currentLang.name + '-' + e : e})
    },
    expand() {
      this.isExpand = !this.isExpand
    }
  },
};
</script>
<style lang="less">
.header-container {
  width: 100%;
  height: 90px;
  background: transparent;
  position: fixed;
  top: 0;
  color: #fff;
  font-size: 14px;
  // font-family: ProximaNova-Regular, ProximaNova;
  transition: background-color 500ms linear;
  z-index: 1000;
  user-select: none;
  &.active {
    background: rgba(0, 0, 0, 0.85);
  }
  &:hover {
    // 导航栏hover
    // background: rgba(10, 10, 10, 0.95);
  }

  .header-box {
    width: 1140px;
    margin: 0 auto;
    // line-height: 90px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .logo {
      float: left;
      height: 90px;
      // margin: 46px 50px 0 240px;
      margin-right: 50px;
      cursor: pointer;
      img {
        margin-top: 30px;
        height: 30px;
      }
    }
    
    .menu {
      float: left;
      height: 90px;
      // line-height: 90px;
      font-weight: 500;
      .item {
        display: inline-block;
        height: 30px;
        margin: 35px 20px 25px;
        // padding: 10px 0;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          &::after {
            content: "";
            display: block;
            margin-top: 5px;
            border-bottom: 4px solid #fff;
            border-radius: 2px;
          }
          .sub-menu-box {
            display: block;
          }
        }
        &.active::after {
          content: "";
          display: block;
          margin-top: 5px;
          border-bottom: 4px solid #fff;
          border-radius: 2px;
        }
        .sub-menu-box {
          display: none;
          position: absolute;
          padding: 30px 0 0;
          background: transparent;
        }
        .sub-menu {
          // top: 80px;
          background: #ffffff;
          text-align: left;
          box-shadow: 0px 1px 4px 0px rgba(51, 51, 51, 0.35);
          border-radius: 3px;
          color: #333333;
          cursor: default;
          &::after {
            content: "";
            display: block;
            clear: both;
          }
          .content {
            // border-right: 1px solid #999;
            line-height: 26px;
            padding: 20px 15px;
            .sub-item {
              cursor: pointer;
              color: #333333;
              font-weight: bold;
              font-size: 14px;
              // height: 30px;
              height: 40px;
              // line-height: 30px;
              line-height: 40px;
              // padding: 3px 10px;
              padding: 0px 10px;
              .sub-icon {
                width: 26px;
                height: 26px;
                float: left;
                margin-right: 15px;
                img {
                  width: 20px;
                  position: relative;
                  top: 4px;
                  margin-right: 20px;
                }
              }
            }
            .sub-item:hover {
              background: rgba(255, 147, 51, 0.2);
            }
          }
          .lf {
            float: left;
            min-width: 200px;
            padding: 20px 0;
            .title {
              font-weight: bold;
              color: #999999;
              padding: 0 20px;
            }
            .content {
              border-right: 1px solid #999;
              line-height: 26px;
              padding: 5px 15px;
              .sub-item {
                cursor: pointer;
                color: #333333;
                font-weight: bold;
                font-size: 14px;
                // height: 30px;
                height: 40px;
                // line-height: 30px;
                line-height: 40px;
                // padding: 3px 10px;
                padding: 0px 10px;
                .sub-icon {
                  width: 26px;
                  height: 26px;
                  float: left;
                  margin-right: 15px;
                  img {
                    width: 20px;
                    position: relative;
                    top: 4px;
                    margin-right: 20px;
                  }
                }
              }
              .sub-item:hover {
                background: rgba(255, 147, 51, 0.2);
              }
            }
            &:last-child {
              .content {
                border: none;
              }
            }
          }
        }
      }
    }
    .lang {
      float: right;
      width: 50px;
      height: 90px;
      line-height: 90px;
      text-align: left;
      cursor: pointer;
      position: relative;
      .current-lang {
        img {
          position: relative;
          top: 8px;
          margin-right: 20px;
        }
      }
      &:hover .changeLang {
        display: block;
      }
      .changeLang {
        width: 120px;
        line-height: normal;
        background: #ffffff;
        text-align: left;
        box-shadow: 0px 1px 4px 0px rgba(51, 51, 51, 0.35);
        border-radius: 3px;
        color: #333333;
        // padding: 5px 15px 10px;
        position: absolute;
        top: 80px;
        cursor: default;
        display: none;
        .item {
          padding: 10px 20px;
          cursor: pointer;
          &:hover {
            background: rgba(255, 147, 51, 0.2);
            // color: #ffffff;
          }
        }
      }
    }
    .d-btn {
      float: right;
      a {
        display: block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        border: 1px solid #ffffff;
        background: transparent;
        border-radius: 20px;
        margin-top: 25px;
        &:hover {
          background: rgba(255,255,255,.2);
        }
      }
    }
  }
}
</style>
