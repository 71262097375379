<template>
  <div class="basic-layout-container">
    <Header /><!--  :menus='menus' -->
    <div>
      <router-view />
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from "../components/Header/index.vue";
import Footer from "../components/Footer/index.vue"
import { routes } from "../router/index";

export default {
  name: "BasicLayout",
  components: { Header, Footer },
  setup() {
    const menus = routes[0].children;
    return {
      menus,
    };
  },
};
</script>
<style lang="less" scoped></style>
