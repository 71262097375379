/** **   request.js   ****/
// 导入axios
import axios from 'axios'

// 1. 创建新的axios实例，
const svc = axios.create({
  // 公共接口
  baseURL: '',
  // 超时时间 单位是ms
  timeout: 50 * 1000
})

// 2.请求拦截器
svc.interceptors.request.use(config => {
  // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  config.data = config.data // 数据转化,也可以使用qs转换
  config['headers'] = {
    // 'Content-Type': 'application/x-www-form-urlencoded' // 配置请求头
    'Content-Type': 'application/json' // 配置请求头
  }
  return config
}, error => {
  Promise.reject(error)
})
// 3.响应拦截器
svc.interceptors.response.use(response => {
  // 接收到响应数据并成功后的一些共有的处理，关闭loading等
  // if (response.data.code === '1004') {
  //   console.log(response.data)
  // }
  return response.data
}, error => {
  return Promise.reject(error.response)
})

// 4.导入文件
export {svc}
