<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="footer-column">
        <div class="logo">
          <img src="../../assets/common/footer-logo.png" alt="">
        </div>
        <!-- <div class="tel">{{t('footer.tel')}}: 400-820-8022</div> -->
        <div class="email">{{t('footer.email')}}: info@worldspire.com</div>
        <div class="link">
          <a target="_blank" href="https://www.facebook.com/WorldspireGlobal/"><img src="../../assets/common/facebook.png" alt=""></a>
          <a target="_blank" href="https://www.linkedin.com/company/worldspireglobal/"><img src="../../assets/common/linkedin.png" alt=""></a>
          <!-- <img src="../../assets/common/twitter.png" alt=""> -->
        </div>
      </div>
      <div class="footer-column">
        <div class="title">{{t('footer.product')}}</div>
        <a @click="linkTo('CDP')">{{t('footer.sub_cdp')}}</a>
        <a @click="linkTo('CDS')">{{t('footer.sub_cds')}}</a>
        <a @click="linkTo('CCM')">{{t('footer.sub_ccm')}}</a>
        <a @click="linkTo('IQ')">{{t('footer.sub_iqplatform')}}</a>
        <a v-if="false">{{t('footer.sub_features')}}</a>
      </div>
      <div class="footer-column">
        <div class="title">{{t('footer.pricing')}}</div>
        <a @click="linkTo('PRICING')">{{t('footer.sub_pricing')}}</a>
      </div>
      <div class="footer-column">
        <div class="title">{{t('footer.solutions')}}</div>
        <a @click="linkTo('RETAIL')">{{t('footer.sub_retail')}}</a>
        <a @click="linkTo('GAME')">{{t('footer.sub_game')}}</a>
        <a @click="linkTo('FINANCIAL')">{{t('footer.sub_financial_services')}}</a>
        <a @click="linkTo('TRAVEL')">{{t('footer.sub_travel_and_hotel')}}</a>
        <a @click="linkTo('MEDIA')">{{t('footer.sub_media')}}</a>
        <a @click="linkTo('AUTOMOTIVE')">{{t('footer.sub_automotive')}}</a>
        <a v-if="false">{{t('footer.sub_eduration')}}</a>
      </div>
      <div class="footer-column">
        <div class="title">{{t('footer.company')}}</div>
        <a @click="linkTo('ABOUT')">{{t('footer.sub_about_us')}}</a>
        <a @click="linkTo('NEWS')">{{t('footer.sub_pres_media')}}</a>
        <!-- <a>{{t('footer.sub_blog')}}</a> -->
        <a @click="linkTo('PARTNERS')">{{t('footer.sub_partners')}}</a>
        <a @click="linkTo('CONTACT')">{{t('footer.sub_contact_us')}}</a>
      </div>
      <div class="footer-column">
        <div class="input-box" :class="{hide: isSuccess}">
          <div class="sign-title">{{t('footer.sign_message')}}</div>
          <div style="width: 480px;">
            <input class="sign-input" :class="{error: emailError}" type="text" @keyup="inputChange($event)" v-model="email" :placeholder="t('footer.email')">
            <button class="sign-btn" @click="subscribe">{{t('footer.subscribe')}}</button>
          </div>
        </div>
        <div class="thanks-box" :class="{active: isSuccess}">
          <div class="thanks-text">{{t("footer.thanks")}}</div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <img src="../../assets/common/footer-bg.png" alt="">
      <div class="web-info">
        <a>{{t('footer.web_info_copyright')}}</a>
        <a>{{t('footer.web_info_rights')}}</a>
        <a target="_blank" href="/privacypolicy">{{t('footer.web_info_privacy_policy')}}</a>
        <a target="_blank" href="/teamservice">{{t('footer.web_info_terms')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { subscribe } from "../../api/api"
export default {
  name: "Footer",
  setup() {
    const { t } = useI18n();
    let email = ref('')
    let emailError = ref(false)
    let isSuccess = ref(false)
    return {
      t,
      email,
      emailError,
      isSuccess
    };
  },
  methods: {
    linkTo(e) {
      let currentLang = JSON.parse(localStorage.getItem('current_lang'))
      this.$router.push({name: currentLang && currentLang.name ? currentLang.name + '-' + e : e})
    },
    inputChange (e) {
      let reg =  new RegExp(/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/);
      if (!reg.test(e.target.value)) {
          this.emailError = true
      } else {
          this.emailError = false
      }
    },
    subscribe() {
      let reg =  new RegExp(/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/);
      this.isSuccess = false
      if (this.email.trim() && reg.test(this.email.trim())) {
        this.emailError = false
        subscribe({email: this.email.trim()}).then(res => {
          if (res.code == 200) {
            var user_id= localStorage.getItem('userId');
            dataLayer.push({
              'event':'purchase',
              'userId':user_id,
              'action':'Subscribe',
              'currency': 'USD',
              'transaction_id': 'WS_0001',
              'value': 0,
              'items': [
                  {
                  'item_id': 'World_Spire',
                  'item_name': 'WorldSpire',
                  }
              ]
            });
            this.isSuccess = true
          }
        })
      } else {
        this.emailError = true
      }
    }
  },
};
</script>
<style lang="less">
.footer-container {
  padding: 30px 0 0;
  .footer-content {
    max-width: 1440px;
    border-top: 1px solid #ccc;
    margin: 0 auto;
    padding-top: 80px;
    display: flex;
    .footer-column {
      flex: 1;
      user-select: none;
      position: relative;
      &:first-child {
        flex: 2;
      }
      &:last-child {
        flex: 3;
        font-size: 14px;
      }
      // .tel {
      //   margin-top: 60px;
      // }
      .tel, .email {
        margin-top: 60px;
        color: #333333;
        margin-bottom: 20px;
        margin-left: 20px;
        font-size: 12px;
      }
      .link {
        margin-left: 20px;
        a {
          display: inline-block;
        }
        img {
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .title {
        font-size: 20px;
        color: #333333;
        line-height: 30px;
        margin-bottom: 30px;
      }
      .input-box {
        width: 480px;
        height: 110px;
        overflow: hidden;
        &.hide {
          width: 0;
          transition: width 0.5s ease-in-out;
        }
      }
      .thanks-box {
        width: 480px;
        height: 0;
        overflow: hidden;
        position: absolute;
        top: -90px;
        // display: none;
        &.active {
          height: 100px;
          transition: height 1s ease-in-out;
          animation-name: myani;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-timing-function: ease-out;
          animation-direction: alternate;
          animation-fill-mode: both;
          // display: block;
          .thanks-text {
            animation-delay: 3s;
            font-size: 30px;
            color: #333333;
            font-weight: bold;
            text-align: center;
          }
        }
        @keyframes myani {
            0%{
                transform: translateY(-100px);
            }
          50% {
                transform: translateY(100px);
            }
          70% {
                transform: translateY(90px);
            }
          80% {
                transform: translateY(100px);
            }
          90% {
                transform: translateY(93px);
            }
          100% {
                transform: translateY(90px);
            }
        }
      }
      .sign-title {
        width: 480px;
        color: #666666;
        line-height:30px;
        margin-bottom: 15px;
      }
      .sign-input {
        width: 260px;
        height: 40px;
        border: 1px solid #dddddd;
        padding: 0 20px;
        margin-bottom: 15px;
        margin-right: 26px;
        font-size: 16px;
        &::placeholder {
          font-size: 16px;
        }
        &.error {
          border-color: #ff0000;
        }
      }
      .sign-btn {
        width: 120px;
        height: 40px;
        font-size: 16px;
        background: #FFFFFF;
        border-radius: 24px;
        color: #FF9333;
        border: 1px solid #FF9333;
        cursor: pointer;
      }
      a {
        display: block;
        margin: 0 0 15px;
        color: #666666;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .footer-bottom {
    width: 100%;
    position: relative;
    font-size: 0;
    img {
      width: 100%;
      height: 80px;
    }
    .web-info {
      position: absolute;
      left: 15%;
      top: 45px;
      a {
        margin-right: 100px;
        font-size: 12px;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}
</style>
