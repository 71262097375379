const enUS = {
  // nav_home: 'HOME',
  // nav_product: 'PRODUCT',
  // nav_solutions: 'SOLUTIONS',
  // nav_company: 'COMPANY',
  nav: {
    home: "HOME",
    product: "PRODUCT",
    pricing: "PRICING",
    solutions: "SOLUTIONS",
    company: "COMPANY",
    sub_products: "Products",
    sub_features: "Features",
    sub_cdp: "CDP",
    sub_iqplatform: "IQ Platform",
    sub_cds: "CDS",
    sub_ccm: "CCM",
    sub_channel: "Channel Manager",
    sub_campaign: "Campaign Manager",
    sub_audience: "Audiencce Manager",
    sub_data: "Data Master",
    sub_about_us: "About Us",
    sub_pres_media: "Press & Media",
    sub_blog: "Blog",
    sub_partners: "Partners",
    sub_contact_us: "Contact Us",
    sub_retail: "Retail",
    sub_game: "Game",
    sub_financial_services: "Financial Services",
    sub_travel: "Travel and Hotel",
    sub_media: "Media",
    sub_automotive: "Automotive",
    btn: "Request a Demo"
  },
  footer: {
    tel: 'Tel',
    product: "Product",
    pricing: "Pricing",
    solutions: "Solutions",
    company: "Company",
    sub_cdp: "CDP",
    sub_iqplatform: "IQ Platform",
    sub_cds: "CDS",
    sub_ccm: "CCM",
    sub_features: "Features",
    sub_pricing: "Pricing",
    sub_retail: "Retail",
    sub_game: "Game", 
    sub_financial_services: "Financial Services",
    sub_travel_and_hotel: "Travel and Hotel",
    sub_media: "Media",
    sub_automotive: "Automotive",
    sub_eduration: "Eduration",
    sub_about_us: "About Us",
    sub_pres_media: "Press & Media",
    sub_blog: "Blog",
    sub_partners: "Partners",
    sub_contact_us: "Contact Us",
    sign_message: 'Subscribe to receive the latest Worldspire news and insights in your inbox.',
    email: 'Email',
    subscribe: 'Subscribe',
    web_info_copyright: 'Copyright © 2022 Worldspire. ',
    web_info_rights: 'All Rights Reserved.',
    web_info_privacy_policy: 'Privacy policy',
    web_info_terms: 'Terms of Service',
    thanks: "Thank You!"
  },
  home: {
    // banner_message: "Meet the next-gen AI-powered customer data application",
    banner_message1: "Meet the next-gen",
    banner_message2: "AI-powered customer data application",
    banner_sub_message: "More intelligent, more real-time, more agile",
    banner_btn: "Get a demo",
    solution_title: "End-to-end Solutions for Enterprises",
    cdp: "CDP",
    cdp_description: "Intelligent enterprise-level customer data platform (CDP) feed data to multiple business units, enabling AI-driven operations and global intelligence marketing across the entire customer lifecycle",
    iq: "IQ Platform",
    iq_description: "Based on global marketing and customer insight scenarios, the enterprise-level predictive AI application platform is designed to deeply explore and maximize the value of customer data assets",
    cds: "CDS",
    cds_description: "The Cross-channel data switch can connect the channel data and empower businesses to make fast, confident decisions based on trusted data to have maximum accuracy, consistency, and context. ",
    ccm: "CCM",
    ccm_description: "As an enterprise marketing technology that supports advertisement data management, analytics, and workflow tools for planning, executing, and measuring campaigns across digital channels.",
    data_pipeline: "DATA PIPELINE",
    module: "MODULE",
    module_channel: "Channel Manager",
    module_channel_text1: 'Channel connections',
    module_channel_text2: 'Performance overview',
    module_campaign: "Campaign Manager",
    module_campaign_text1: 'Lookalike campaign',
    module_campaign_text2: 'Retargeting campaign',
    module_campaign_text3: 'Budget allocation',
    module_campaign_text4: 'Marketing intelligence',
    module_audience: "Audience Manager",
    module_audience_text1: 'Customer profiles',
    module_audience_text2: 'Channel audiences',
    module_audience_text3: 'Custom audiences',
    module_audience_text4: 'Channel labels',
    module_audience_text5: 'Customs labels',
    module_audience_text6: 'Labeling intelligence',
    module_data: "Data Master",
    module_data_text1: 'OneID',
    module_data_text2: 'Data integration',
    module_data_text3: 'Data warehouse',
    module_data_text4: 'Consent Management',
    customer: "OUR CUSTOMERS",
    ak_dec: "Anker invested in the use of customer data among other services and products to improve their management of data assets. Through these efforts they were able to double the growth of their brand value and their scale of business and improve their global reputation.",
    ch_dec: 'Spring Airlines used AI-based passenger selection methods to improve their comprehensive repurchase rates. This resulted in the conversion rate increasing from 0.7% to 6.7% of 1 million.',
    sb_dec: "Starbucks added personalized marketing strategies to increase their audience with the implementation of AI technologies. The result was a 46% increase in purchase rate. ",
    mk_dec: 'Michael Kors saw a 270% increase in click-through rate from AI-based personalized recommendation strategies. This resulted in a 21% increase in the efficiency of cross-selling.'
  },
  form: {
    message: "We're here to help",
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Phone Number',
    company: 'Company',
    job: 'Job Title',
    country: 'Country',
    comments: 'Comments',
    btnMessage: 'Submit',
    thanks_message1: "Thank you for getting in touch, ",
    thanks_message2: "we will reach back to you in a short time.",
  },
  product: {
    cdp: {
      banner_title: 'CDP',
      banner_sub_title: 'Customer Data Platform',
      banner_description: "Through intelligent management of assets and enhancement of customer lifecycle value, Worldspire gives companies the abilities to optimize the potential of their data. Seamless data applications and intelligent closed loop global marketing systems become possible with the platform's powerful data collection, processing, and governance capabilities with custom analytics and BI and AI-driven insights. Enjoy data-driven marketing freedom with 360-degree customer insights, smart marketing, and personalization.",
      banner_btn: 'Get a demo',
      module: 'MODULE',
      module_intelligent: 'Intelligent Marketing',
      module_intelligent_dec: 'Realized personalized real-time data-driven intelligent marketing',
      module_insight: 'Customer Insight & BI',
      module_insight_dec: 'Allow multi-dimensional analysis of massive customer data, custom insights and BI',
      module_lifecycle: 'Customer Lifecycle Management',
      module_lifecycle_dec: 'Customer experience optimization and intelligent customer lifecycle management',
      module_loyalty: 'Loyalty & Repurchase',
      module_loyalty_dec: 'Improve customer loyalty, repurchase rate, LTV, and reduce churn rate',
      module_service: 'Intelligent Customer Service',
      module_service_dec: 'Use real-time insights to deliver better customer services',
      data_pipeline: "CDP DATA PIPELINE",
      features: 'FEATURES',
      customer_profile: 'Customer Profile',
      customer_profile_description: "Single view of the customer means you have one location for every individual you do business with that provides an overview of all the data you've collected on them. It unifies your customer's touchpoints across all platforms and channels. Understand your customer journey. It gives all departments valuable information to improve their performance.",
      custom_audiences: 'Custom Audiences',
      custom_audiences_description: 'You can easily and visually define this audience with criteria, then configure audience integrations to push notification and email partners. This is essential for pinpointing bespoke audiences. The accuracy and complexity of your audience translate into high-precision targeting. In addition to increasing ROI, understanding your target audience allows you to build relationships and better communicate with consumers.',
      custom_labelling: 'Custom Labelling',
      custom_labelling_description: 'Config custom label triggers by using selected attributes, and calculated attributes with machine learning technology. Using custom labels is more stable and faster data analysis on a single, pre-defined use case. This is because the data set has already been structured and transformed. It is also easier compliance with GDPR, HIPAA, and CCPA standards. This is because helps omit any sensitive data (PII) prior to loading in the target system.',
      one_id: 'One ID',
      one_id_description: 'One ID gives visibility and control over the management of known and anonymous user identities across all apps and platforms. It allows marketers to accurately identify customers in key moments of their journey to support robust targeting and personalization and to deliver consistent user experiences across all devices, touchpoints, and channels.',
      data_integration: 'Data Integration',
      data_integration_description: 'A vendor-neutral approach provides a key piece of the technology puzzle by providing deep integrations with the leading tech providers to help your teams and tools work together to drive budget efficiency, increase technology utilization, and create consistent customer experiences. Also, it improves collaboration and facilitates omnichannel customer experiences. It significantly saves time by reducing the need for manual data gathering and analysis. ',
      data_warehouse: 'Data Warehouse',
      data_warehouse_description: "Adding data sources to a data warehouse enables organizations to ensure that they are collecting consistent and relevant data from that source. You don't need to wonder whether the data will be accessible or inconsistent as it comes into the system. This ensures higher data quality and data integrity for sound decision-making. Data in a warehouse is in such consistent formats that it is ready to be analyzed. It also provides the analytical power and a more complete dataset to base decisions on hard facts. Therefore, decision-makers no longer need to rely on hunches, incomplete data, or poor-quality data and risk delivering slow and inaccurate results.",
      // worldspire: 'WHY Worldspire ?',
      // ws_sub_title: "We're everywhere you need us to be",
      // ws_sub_message: 'We are a global company with strategically located offices, personnel, and technology infrastructure, allowing us to offer our customers capabilities for managing data based on geographic considerations.',
      // data: 'Real-time Data',
      // data_sub_title: 'Real-time Data',
      // data_sub_message: '',
      // business: 'Built for the entire business',
      // business_sub_title: 'Built for the entire business',
      // business_sub_message: '',
      // security: 'Privacy and Security',
      // security_sub_title: 'Privacy and Security',
      // security_sub_message: '',
      // team: 'Our team is your team',
      // team_sub_title: 'Our team is your team',
      // team_sub_message: '',
    },
    iq: {
      banner_title: 'IQ Platform',
      // banner_description: 'IQ is a leading intelligent AI platform for Uni marketing, enabling organizations to maximize the value of their customer data and data assets, creating deep and predictive insights into a range of customer life-cycle scenarios. Leveraging Deep Learning, Federated Learning, NLP, and other technologies, IQ helps B2C companies sharpen their technology-driven edges in customer lifecycle management and intelligent operations. IQ is results-driven; it helps business teams optimize critical core business KPIs',
      banner_description: "One of the top AI platforms for Uni marketing maximizes the value of customer data and data assets, creates deep and predictive insights into customer life-cycle scenarios by leveraging technologies like deep learning, federated learning, and NLP. Many B2C companies have been given their edges in customer lifecycle management and intelligent operations by utilizing IQ's technology. Driven by results, IQ allows teams to identify and optimize key performance indicators. ",
      banner_btn: 'Get a demo',
      benefits: 'BENEFITS',
      benefits_title1: 'Massive Data, High Performance',
      benefits_dec1: 'Combine with customer data platform and data development platform (CDP) to easily and efficiently process and transfer massive amounts of data and operate AI model computing tasks in parallel.',
      benefits_title2: 'Rapid deployment to deliver business value',
      benefits_dec2: 'The IQ platform involves model products for over 20 Uni marketing scenarios; the implementation process is fast without the need to deploy a large number of assets. Easy for business personnel to get started and extract business value quickly.',
      benefits_title3: 'Open platform for flexible data use',
      benefits_dec3: 'Model output insights implemented in multiple scenarios, channels or tools. Business personnel can view visual reports for marketing reach, smart tag creation, and AOI data interface output scenarios.',
      benefits_title4: 'Data Visualization + BI',
      benefits_dec4: 'Scenario-based model data insights and business intelligence. Users can present an overview of data reports and custom IQ insights from the CDP (Customer Data Platform) to internal multi-role users.',
      features: 'FEATURES',
      sub_title: 'IQ Platform',
      sub_dec: 'IQ is a leading intelligent AI platform for Uni marketing, enabling organizations to maximize the value of their customer data and data assets, creating deep and predictive insights into a range of customer life-cycle scenarios. Leveraging Deep Learning, Federated Learning, NLP, and other technologies, IQ helps B2C companies sharpen their technology-driven edges in customer lifecycle management and intelligent operations. IQ is results-driven; it helps business teams optimize critical core business KPIs'
    },
    cds: {
      banner_title: 'CDS',
      banner_sub_title: 'Cross-channel Data Switch',
      banner_description: 'The CDS can connect the channel data and empower businesses to make fast, confident decisions based on trusted data to have maximum accuracy, consistency, and context. It easily imports and aggregates user-associated data from any channel you are using – Google, Facebook, Tiktok, Bing, and many other sources. ',
      banner_btn: 'Get a demo',
      data_pipeline: 'CDS DATA PIPELINE',
      features: 'FEATURES',
      features_title1: 'Channels Connection',
      features_title2: 'Performance Overview',
      features_title3: 'Channel Audiences',
      features_title4: 'Channel Labels',
      features_dec1: 'Our integrations are battle-tested and reliable – so you never need to worry about API updates or quota limits. Moving pixel tracking to the server-side and getting a better understanding of who your customers are and overview their full journey across channels. ',
      features_dec2: 'It connects and transforms to ensure integrity – consolidates multiple channel data and builds custom pipelines to transform data into dashboards. It connects any marketing data source to your dashboard and lets you focus on the analysis. So it empowers you to target audience cohorts based on recent actions and stated preferences across channels. ',
      features_dec3: 'Builds precise audiences based on live-updating data – Use data flowing into our platform immediately for campaign triggering and cross-channel personalization.',
      features_dec4: 'Config channel label triggers and only fire when appropriate consent is in place .'
    },
    ccm: {
      banner_title: 'CCM',
      banner_sub_title: 'Cross-channel Campaign Manager',
      banner_description: 'CCM is a web-based ad data management system for advertisers and agencies. As an enterprise marketing technology that supports customer data management, analytics, segmentation, and workflow tools for planning, executing, and measuring campaigns across digital channels. This includes a robust set of features for budget allocation, campaign planning, and performance reporting. It makes sure you get to your destination, which of course means hitting goals for your program. The Pace module tracks your spend over the course of the month, quarter, or other period you specify and adjusts your bidding targets to keep you on track. ',
      banner_btn: 'Get a demo',
      data_pipeline: 'CCM DATA PIPELINE',
      data_flow: 'CCM DATA FLOW',
      features: 'FEATURES',
      features_title1: 'Lookalike Campaign',
      features_dec1: "Improves lookalike audiences. Lookalike campaigns based on first-party data can help acquire higher-quality customers. Characteristics of your best customers can be used to target your best prospects. With profiles on your best customers available, you can create customer lists using any customer attributes as the basis of lookalike campaigns. With lookalike campaigns powered by robust first-party data, new customer targeting is more effective and efficient. ",
      features_title2: 'Retargeting Campaign',
      features_dec2: "These hyper-specific segments, built with unified data, then become the foundation for social media targeting ad audiences like Facebook. With these segments, you can target based on specific behavior, like web pages or products viewed. Alternatively, these segments can help prevent wasted ad spending on current customers by allowing you to suppress current customers (or other audiences you prefer not to target). ",
      features_title3: 'Budget Allocation',
      features_dec3: "By dividing your budget into manageable components, you can have better visibility into how spending is allocated and ensure your investments are aligned with your strategy. We have developed a strategic allocation model that takes a divide- and-conquer approach to budget planning, so campaign spending can be managed separately from out-of-campaign spending. Taking this approach simplifies strategic budget planning, gives you better visibility into how spending aligns with strategy and makes it easier to understand the implications of shifting investments from one area to another. ",
      features_title4: 'Marketing Intelligence',
      features_dec4: "Likelihood Models – Understand whether customers are likely to buy, likely to convert, likely to pay full price, and more. CCM advanced modelsaccurately identify critical segments of your customers. CCM also lets machine learning do the work of grouping similar sets of customers together, based on pattern in the data. Surface marketing opportunities are based on behaviors, personas, seasonality, and more. It also gives customers the right recommendations at the individual level. From discounts to the next best products, increase conversion with the right offer. You can also give marketers recommendations about which campaigns to run.",
    },
    worldspire: {
      title: 'Why Worldspire ?',
      sub_title1: "We're everywhere you need us to be",
      sub_title2: 'Real-time Data',
      sub_title3: 'We provide deeper understanding of your market',
      sub_title4: 'We improve your marketing strategies',
      sub_title5: 'Built for the entire business',
      sub_title6: "Privacy and Security",
      sub_title7: 'Our team is your team',
      sub_dec1: "We are a global company with strategically located offices, personnel, and technology infrastructure, allowing us to offer our customers capabilities for managing data based on geographic considerations.",
      sub_dec2: "Our platform is built for truly real-time collection, standardization, transformation, enrichment, and activation of customer data. As customer needs and preferences change, your business will be ready to keep pace and deliver the right experience.",
      sub_dec3: "We help you to get a much better understanding of the way your consumers behave online, define your overall demographics, and identify the ways in which they can improve the overall customer experience. ",
      sub_dec4: "In today's world, your company's overall digital marketing strategy is more important than ever. Data will be able to tell you which of your marketing campaigns and tactics your target audience responds to the most and prefers, so that you can create effective and targeted advertisements in the future.",
      sub_dec5: "Worldspire helps all teams across the enterprise collect, understand, and activate their data.",
      sub_dec6: "From the beginning, data security and customer privacy have been at the forefront of our development strategy. Worldspire has achieved a pedigree of 3rd party security and privacy certifications including ISO 27001 and ISO 9001 and also complies GDPR, CCPA, LGPD and HIPAA. ",
      sub_dec7: "Leverage our dedicated team of customer success and digital strategists to support your customer experience efforts and business goals.",
    }
  },
  company: {
    about: {
      banner_title: 'About Us',
      text1: 'Building the next generation of intelligent business applications, with AI and data science at the core',
      text2: "Worldspire is a customer data technology and AI company headquartered in Hong Kong with offices in Beijing, Shenzhen, Shanghai and Silicon Valley.", 
      text3: 'We combine cutting-edge big data and AI to tackle problems in areas of marketing, personalization, and customer analytics.',
      text4: 'We have served 300+ mid to large global enterprises in sectors of retail, automotive & mobility, travel, finance and ecommerce.',
      awards_title: 'PRESTIGIOUS AWARDS',
      awards_dec1: 'The only Chinese company listed on the 2018 Red Herring TOP 100 Global (Big Data)',
      awards_dec2: '2018 Forbes Top 50 Potential Chinese Companies',
      awards_dec3: '2018 Top 100 Artificial Intelligence Companies',
      awards_dec4: '2018 Top 50 Chinese Unicorns in Big Data Industry',
      awards_dec5: 'Selected as a Tencent AI Accelerator Phase III company among 1500+ competitors',
      awards_dec6: 'Selected as one of the 15 Microsoft Accelerator companies among 1000 competitors',
      certification_title: 'ISO9001 certification and high-tech enterprise certification',
      certification_dec1: 'Quality management system certification',
      certification_dec2: 'National high-tech enterprise',
      patents_title: '50+ product-related computer software works and patents',
      patents_item1: 'Enterprise Big Data Encryption Software',
      patents_item2: 'Customer Tag Analysis Software',
      patents_item3: 'Worldspire Robotics Learning Service Software',
      patents_item4: 'Customer Data Management Platform Software',
      patents_item5: 'Big Data Report Management Software',
      patents_item6: 'Worldspire China Digital Marketing Platform Software',
      patents_item7: 'Data Analysis Funnel Software',
      patents_item8: 'Enterprise-level Intelligent Data Platform',
      patents_item9: 'Worldspire API Network Management Software',
      patents_item10: 'User Marketing Lens Analysis Software',
      patents_item11: 'Worldspire Code Management System',
      patents_item12: 'Worldspire A/B Test Optimization Software',
      city1: 'SHANGHAI',
      city2: 'NEW YORK',
      city3: 'SAN FRANCISCO',
      city4: 'ATLANTA',
      city5: 'SEOUL',
      city6: 'LONDON',
      city7: 'BARCELONA',
      city8: 'TOKYO',
      // citys:[
      //   'SHANGHAI', 'NEW YORK', 'SAN FRANCISCO', 'ATLANTA', 'SEOUL', 'LONDON', 'BARCELONA', 'TOKYO'
      // ],
      customer: 'OUR CUSTOMERS',
      org_title1: "Worldspire endeavors",
      org_title2: "to follow privacy laws and regulations",
      org1: "CCPA",
      org2: "GDPR",
      org3: "HIPAA",
      org4: "LGPD",
    },
    contact: {
      banner_title: 'Contact Us',
      city1: 'Hong Kong Headquarters',
      city2: 'Shenzhen',
      city3: 'Beijing',
      city4: 'Silicon Valley',
      city5: 'Shanghai',
      city6: 'Seoul',
      addr1: 'Room 1003, 10/F, Tower 1, Lippo Centre,89 Queensway, Admiralty, Hong Kong',
      email1: 'E-mail:',
      addr2: '6F, LOFTB, South UpperHills, 5001 Huanggang Road,Futian District, Shenzhen',
      addr3: 'B17F, Block D, Diplomatic Office Building, 19 Dongfang East Road, Liangmaqiao,Chaoyang District, Beijing',
      addr4: '111 Deerwood Rd Suite 200 San Ramon, CA 94583',
      // email2: 'E-mail: sf@chinapex.com',
      addr5: 'Suit 902-908, Shanghai Point, No.1098 Dongdaming Road, Hongkou District, Shanghai, China',
      addr6: 'Room 101, KH, 57 Yeonhui-ro 41-gil, Seodaemum-gu, Seoul',
    },
    partners: {
      banner_title: "Partners",
      parteners_title: "Our Solution Partners",
      parteners_dec: "We are partnered with 16+ main digital marketing channels and e-commerce platforms; this makes data tracking more accurate and reliable!",
      join_title: "Join Worldspires's partner",
      join_dec: "Sign up below and we will contact you shortly with more information about our solution partner program.",
      join_btn: "Become a Partner"
    },
    blog: {
      banner_title: "Blog"
    },
    news: {
      banner_title: "Press & Media",
      read_more: "Read more",
      list: {
        item1: {
          title: "Worldspire Launches Cross Channel Data Switch to improve digital advertising ROI.",
          content: "The pandemic has changed the way of consuming, thus the form of marketing. Digital channels have become the most vital marketing channels. It is reported that digital ad spending increased by 14.3% between June 2020 and February 2021, one of the highest six-month increases yet. However, in this process, How to use the data assets generated, How to manage the data in a whole picture, How to define the effectiveness of a specific campaign, have become an issue.",
        }
      }
    },
    news_detail: {
      banner_title: "Press & Media",
      detail1: {
        title: "Worldspire launches Cross-channel Data Switch",
        date: "2022-4-1"
      }
    }
  },
  solutions: {
    retail: {
      banner_title: 'Retail',
      sub_title1: 'CHALLENGE',
      sub_dec1: "Between evolving consumer tastes and expectations, quickly shifting technology trends, and the pandemic, brands face enormous challenges creating shopping experiences that customers will love— both in-store and online. And when you add in new privacy and data regulations that bring a wide variety of other challenges for managing customer experience, it's hard for brands to not only delight their buyers but to also improve operational efficiency, agility, and ultimately, revenue.",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "To deliver shopping experiences that stand out, brands must understand customers through their data and put that understanding at the heart of every experience they create. How do they do it? It starts by unifying the first-party data a brand already has of their customer to create a complete profile of them. Armed with real-time visitor profiles for campaign orchestration, brands can always guarantee an “add to cart” by creating relevant and personalized messages at every point of interaction. ",
      sub_title3: "We Can Help",
      sub_dec3: "Customer Views Beyond Acquisition, Worldspire helps you build a trusted, unified customer data foundation with insights unique to your brand that can be seamlessly integrated throughout your entire stack. Whyis this so hugely helpful? Because it makes every tool you already use today more powerful! With a strong integration marketplace and without divided loyalties to native execution tools, Worldspire is uniquely positioned to help you build a vendor and channel-agnostic view of your customer as a foundation for your customer experience strategy. "
    },
    game: {
      banner_title: 'Game',
      sub_title1: 'CHALLENGE',
      sub_dec1: "The online gaming market has evolved at a rapid pace over the past decade and a half. Out of all the digital trends that have garnered considerable traction over the past few years, the online gaming sphere is at the forefront. The online gaming market is booming at an impressive pace due to an array of factors such as increasing penetration of affordable internet, access to online games, and large-scale development of unique and engaging multiplayer games. From a casual pastime activity, video games have grown into a giant industry that ripples its influence on the global scale, creating jobs, products, and organizing countless competitive events that generate tremendous cash flow. This phenomenon has driven both veteran and fledgling business entrepreneurs to compete in the video game industry. ",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "Suffice it to say that gaming and online gambling have become quite popular industries and have a massive appetite for them. What is even more important, the appetite is going to increase even more in the future. Why is this so? Because it's relatively easy to build and launch an online game website where you will sell your services – the most challenging part is the development level during which the games are created and then picked up by different operators. Moreover, new games are being created daily, which makes it rather challenging to pick up new users, while old games sometimes have more operators.",
      sub_title3: "We Can Help",
      sub_dec3: "It closely analyzes which sections of character creation, tutorial, and in-app purchase occur. Based on this, we can create an environment where players can experience richer content without leaving. If you are serving multiple games, you can cross-analysis game-specific data. Data can be used, such as securing only users who use our games a lot and purchase more than a certain number of in-app products at the same time. Organize scenarios for each situation and conduct campaigns according to the user's play tendency. It automatically sends/exposes in-app messages and notices to provide users with more intimate and in-depth play. Optimize in-game performance and the end-user experience.",
    },
    financial: {
      banner_title: 'Financial Services',
      sub_title1: 'CHALLENGE',
      sub_dec1: "Experience is the new differentiator for financial service organizations and it's also how your customers expect you to help them achieve their lifetime goals. Power better experiences across the financial journey and drive deeper customer insight with unified and trusted customer data. ",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "Do more to be there for your customers like never before. By collecting and connecting relevant data to elevate the digital experience, your brand can personalize experiences beyond just products and services to truly form relationships that establish you as the trusted and reliable advisor. ",
      sub_title3: "We Can Help",
      sub_dec3: "With unified and trusted customer data, financial services organizations can transform customer interactions and deliver the right experiences, right when it matters. More than 10 leading financial services and top payment services industry leaders trust Worldspire to power their customer data collection, cleansing, activation, and robust insight capturing practices that are scalable and meet security requirements across the globe.",
    },
    media: {
      banner_title: 'Media',
      sub_title1: 'CHALLENGE',
      sub_dec1: "Customers engage with media brands across mobile, desktop, and connected devices throughout their day, with an average screen time totaling up to 12 hours per day. As media has become a critical part of customers' daily lives, customers' expectations have risen, and brands are tasked with creating seamless, contextual, and concurrent experiences across every device. This represents a big opportunity and a big challenge for media companies, especially those looking to engage customers across mobile and OTT channels. ",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "With interactions occurring across many devices and channels, media companies can no longer rely on legacy systems to understand who their customer is, how and when to reach them, and how to measure the influence of marketing, product, and engineering initiatives on the bottom line. That's why many forward-thinking media companies have started to consider using a customer data platform to unify and orchestrate their customer data. ",
      sub_title3: "What can we help? ",
      sub_dec3: "To find the right CDP, marketers need to consider their current data maturity and how it can be used as well as what their future goals are and what data will be needed to achieve those goals. Data maturity levels can be broken down across four maturity levels, from least to most mature: Foundational, Insight and Experimentation, Omnichannel Engagement, and Continuous Optimization. This blog will take you through use cases at each level to help you determine which CDP features are relevant to your situation and find the CDP that will help you meet your current and future business needs.",
    },
    travel: {
      banner_title: 'Travel and Hotel',
      sub_title1: 'CHALLENGE',
      sub_dec1: "It's no secret that the travel & hospitality industry had to make unparalleled adjustments during the global pandemic. Over the last 18 months, the industry has shifted from abrupt “no travel” regulations to a resurgence of travelers. Hospitality has had to switch marketing tactics to keep in touch with customers. ",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "Travelers Crave Experiences. If you're in the travel and hospitality industry, you're selling more than a flight or an overnight stay. You're providing experiences that drive conversions, loyalty, and repeat bookings. ",
      sub_title3: "We Can Help",
      sub_dec3: "To innovate, drive growth, reduce costs, and ultimately create great customer experiences - you need to understand travelers and customers through data. Worldspire helps travel and hospitality businesses successfully collect, unify, and activate trusted data in real- time - so companies can deliver the right experiences for their customers when it matters.",
    },
    automotive: {
      banner_title: 'Automotive',
      sub_title1: 'CHALLENGE',
      sub_dec1: "Which customers are ready to buy soon? Usually, the automotive companies had plenty of data—from dealerships, apps, maintenance facilities, websites, advertising, marketing tools, and social media—but it was so disjointed and siloed that the companies often relied on gut feelings and limited data sets to form its marketing and advertising strategy. ",
      sub_title2: 'Why Does it Matter? ',
      sub_dec2: "To optimize marketing ROI, many companies needed to personalize the customer experience by gathering all its first-party customer data and merging it with third-party data. Unfortunately, many of the tools and data sources the companies used did not easily integrate with each other. And it didn't help that most of the first-party data were either hidden or scattered among various corporate departments. To further complicate matters, each vehicle model had its own advertising data, managed by separate agencies. ",
      sub_title3: "How can we help?",
      sub_dec3: "Worldspire enterprise CDP provided the companies with an easy, plug-and-play solution that was up and running in a few weeks and integrated all its disparate data sources. Their multiple advertising agencies, each managed by a different product team, could share necessary data and still work independently. Our clients learned how to figure out who had almost decided to purchase and to optimize such customers' experiences to improve conversion rates. They created behavior-based audience segments to personalize communications for different segments—driving conversions, revenue growth, and more cost-effective advertising. Unified customer data and predictive modeling improved close rates and ad performance, cut costs, and improved collaboration and communication between marketing and sales teams. Our clients now understand how data can transform other functions of its organization. It's using post-sales customer data to understand how to design new products and services that improve revenue and cement customer brand loyalty. Perhaps the biggest change of all at SAICMOTOR is that the company now knows that with the right customer data, “the possibilities to improve the customer experience are endless” – when you have a customer 360 view.",
    }
  },
  pricing: {
    banner_title: "Which plan is right for you?",
    banner_text: "Plans scale with your growth",
    free: {
      title: "Free",
      btn: "Create a free account",
      price: "$0/mo",
      sub_title: "WHAT YOU CAN DO", 
      text1: "Includes 1,000 visitors/mo",
      text2: "2 Sources",
      text3: "300+ Integrations",
    },
    team: {
      title: "Team",
      btn: "Try for free",
      price: "Starts at $80/mo",
      sub_title: "ALL OF FREE PLUS", 
      text1: "Includes 10,000 visitors/mo",
      text2: "Unlimited Sources",
      text3: "Includes 1 Data Warehouse destination",
    },
    business: {
      title: "Business",
      btn: "Contact Us",
      price: "Custom pricing",
      sub_title: "ALL OF TEAM PLUS", 
      text1: "Custom Volume",
      text2: "Single View of the Customer",
      text3: "Data Governance",
      text4: "Advanced Roles & permissions",
      text5: "Historical replay",
    },
    compare_plans: 'COMPARE PLANS'
  }
};

export default enUS;
export const news_detail = enUS.company.news_detail
