import { createRouter, createWebHistory } from "vue-router";
import BasicLayout from "Layouts/BasicLayout.vue";
import PageRoute from "Layouts/PageRoute.vue";

const routes = [
  {
    path: "/",
    name: "BasicLayout",
    component: BasicLayout,
    redirect: { name: "HOME" },
    children: [
      {
        path: "/",
        name: "HOME",
        component: () => import("../views/dashboard/index.vue"),
      },
      {
        path: "/products",
        name: "PRODUCTS",
        redirect: { name: "CDP" },
        component: PageRoute,
        children: [
          {
            path: "/products/cdp",
            name: "CDP",
            meta: {menu: 'products'},
            component: () => import("../views/products/cdp.vue"),
          },
          {
            path: "/products/iq",
            name: "IQ",
            meta: {menu: 'products'},
            component: () => import("../views/products/iq.vue"),
          },
          {
            path: "/products/ccm",
            name: "CCM",
            meta: {menu: 'products'},
            component: () => import("../views/products/ccm.vue"),
          },
          {
            path: "/products/cds",
            name: "CDS",
            meta: {menu: 'products'},
            component: () => import("../views/products/cds.vue"),
          },
        ],
      },
      {
        path: "/pricing",
        name: "PRICING",
        component: () => import("../views/dashboard/index.vue")
      },
      {
        path: "/solutions",
        name: "SOLUTIONS",
        component: PageRoute,
        children: [
          {
            path: "/solutions/retail",
            name: "RETAIL",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/retail.vue"),
          },
          {
            path: "/solutions/game",
            name: "GAME",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/game.vue"),
          },
          {
            path: "/solutions/financial",
            name: "FINANCIAL",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/financial.vue"),
          },
          {
            path: "/solutions/travel",
            name: "TRAVEL",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/travel.vue"),
          },
          {
            path: "/solutions/media",
            name: "MEDIA",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/media.vue"),
          },
          {
            path: "/solutions/automotive",
            name: "AUTOMOTIVE",
            meta: {menu: 'solutions'},
            component: () => import("../views/solutions/automotive.vue"),
          }
        ]
      },
      {
        path: "/company",
        name: "COMPANY",
        component: PageRoute,
        children: [
          {
            path: "/company/about",
            name: "ABOUT",
            meta: {menu: 'company'},
            component: () => import("../views/company/about.vue"),
          },
          {
            path: "/company/news",
            name: "NEWS",
            meta: {menu: 'company'},
            component: () => import("../views/company/news.vue"),
          },
          {
            path: "/company/blog",
            name: "BLOG",
            meta: {menu: 'company'},
            component: () => import("../views/company/blog.vue"),
          },
          {
            path: "/company/partners",
            name: "PARTNERS",
            meta: {menu: 'company'},
            component: () => import("../views/company/partners.vue"),
          },
          {
            path: "/company/contact",
            name: "CONTACT",
            meta: {menu: 'company'},
            component: () => import("../views/company/contact.vue"),
          },
          {
            path: "/company/article/:id", 
            name: "NEWSDETAIL",
            meta: {menu: 'company'},
            component: () => import("../views/company/news-detail.vue"),
          }
        ]
      },
      {
        path: '/pricing',
        name: 'PRICING',
        meta: {menu: 'pricing'},
        component: () => import("../views/pricing/pricing.vue")
      },
      {
        path: "/zh",
        name: "zh",
        component: PageRoute,
        redirect: { name: "zh-HOME" },
        children: [
          {
            path: "/zh",
            name: "zh-HOME",
            component: () => import("../views/lang/zh-cn/dashboard/index.vue"),
          },
          {
            path: "/zh/products",
            name: "zh-PRODUCTS",
            redirect: { name: "zh-CDP" },
            component: PageRoute,
            children: [
              {
                path: "/zh/products/cdp",
                name: "zh-CDP",
                meta: {menu: 'products'},
                component: () => import("../views/lang/zh-cn/products/cdp.vue"),
              },
              {
                path: "/zh/products/iq",
                name: "zh-IQ",
                meta: {menu: 'products'},
                component: () => import("../views/lang/zh-cn/products/iq.vue"),
              },
              {
                path: "/zh/products/ccm",
                name: "zh-CCM",
                meta: {menu: 'products'},
                component: () => import("../views/lang/zh-cn/products/ccm.vue"),
              },
              {
                path: "/zh/products/cds",
                name: "zh-CDS",
                meta: {menu: 'products'},
                component: () => import("../views/lang/zh-cn/products/cds.vue"),
              },
            ],
          },
          {
            path: "/zh/pricing",
            name: "zh-PRICING",
            component: () => import("../views/lang/zh-cn/pricing/pricing.vue")
          },
          {
            path: "/zh/solutions",
            name: "zh-SOLUTIONS",
            component: PageRoute,
            children: [
              {
                path: "/zh/solutions/retail",
                name: "zh-RETAIL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/retail.vue"),
              },
              {
                path: "/zh/solutions/game",
                name: "zh-GAME",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/game.vue"),
              },
              {
                path: "/zh/solutions/financial",
                name: "zh-FINANCIAL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/financial.vue"),
              },
              {
                path: "/zh/solutions/travel",
                name: "zh-TRAVEL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/travel.vue"),
              },
              {
                path: "/zh/solutions/media",
                name: "zh-MEDIA",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/media.vue"),
              },
              {
                path: "/zh/solutions/automotive",
                name: "zh-AUTOMOTIVE",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/automotive.vue"),
              }
            ]
          },
          {
            path: "/zh/company",
            name: "zh-COMPANY",
            component: PageRoute,
            children: [
              {
                path: "/zh/company/about",
                name: "zh-ABOUT",
                meta: {menu: 'company'},
                component: () => import("../views/lang/zh-cn/company/about.vue"),
              },
              {
                path: "/zh/company/news",
                name: "zh-NEWS",
                meta: {menu: 'company'},
                component: () => import("../views/lang/zh-cn/company/news.vue"),
              },
              {
                path: "/zh/company/blog",
                name: "zh-BLOG",
                meta: {menu: 'company'},
                component: () => import("../views/lang/zh-cn/company/blog.vue"),
              },
              {
                path: "/zh/company/partners",
                name: "zh-PARTNERS",
                meta: {menu: 'company'},
                component: () => import("../views/lang/zh-cn/company/partners.vue"),
              },
              {
                path: "/zh/company/contact",
                name: "zh-CONTACT",
                meta: {menu: 'company'},
                component: () => import("../views/lang/zh-cn/company/contact.vue"),
              },
              {
                path: "/zh/company/article/:id", 
                name: "zh-NEWSDETAIL",
                meta: {menu: 'company'},
                component: () => import("../views/company/news-detail.vue"),
              }
            ]
          },
        ],
      },
      {
        path: "/kr",
        name: "kr",
        component: PageRoute,
        redirect: { name: "kr-HOME" },
        children: [
          {
            path: "/kr",
            name: "kr-HOME",
            component: () => import("../views/lang/Kr/dashboard/index.vue"),
          },
          {
            path: "/kr/products",
            name: "kr-PRODUCTS",
            redirect: { name: "kr-CDP" },
            component: PageRoute,
            children: [
              {
                path: "/kr/products/cdp",
                name: "kr-CDP",
                meta: {menu: 'products'},
                component: () => import("../views/lang/Kr/products/cdp.vue"),
              },
              {
                path: "/kr/products/iq",
                name: "kr-IQ",
                meta: {menu: 'products'},
                component: () => import("../views/lang/Kr/products/iq.vue"),
              },
              {
                path: "/kr/products/ccm",
                name: "kr-CCM",
                meta: {menu: 'products'},
                component: () => import("../views/lang/Kr/products/ccm.vue"),
              },
              {
                path: "/kr/products/cds",
                name: "kr-CDS",
                meta: {menu: 'products'},
                component: () => import("../views/lang/Kr/products/cds.vue"),
              },
            ],
          },
          {
            path: "/kr/pricing",
            name: "kr-PRICING",
            component: () => import("../views/lang/Kr/pricing/pricing.vue")
          },
          {
            path: "/kr/solutions",
            name: "kr-SOLUTIONS",
            component: PageRoute,
            children: [
              {
                path: "/kr/solutions/retail",
                name: "kr-RETAIL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/Kr/solutions/retail.vue"),
              },
              {
                path: "/kr/solutions/game",
                name: "kr-GAME",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/Kr/solutions/game.vue"),
              },
              {
                path: "/zh/solutions/financial",
                name: "zh-FINANCIAL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/zh-cn/solutions/financial.vue"),
              },
              {
                path: "/kr/solutions/travel",
                name: "kr-TRAVEL",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/Kr/solutions/travel.vue"),
              },
              {
                path: "/kr/solutions/media",
                name: "kr-MEDIA",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/Kr/solutions/media.vue"),
              },
              {
                path: "/kr/solutions/automotive",
                name: "kr-AUTOMOTIVE",
                meta: {menu: 'solutions'},
                component: () => import("../views/lang/Kr/solutions/automotive.vue"),
              }
            ]
          },
          {
            path: "/kr/company",
            name: "kr-COMPANY",
            component: PageRoute,
            children: [
              {
                path: "/kr/company/about",
                name: "kr-ABOUT",
                meta: {menu: 'company'},
                component: () => import("../views/lang/Kr/company/about.vue"),
              },
              {
                path: "/kr/company/news",
                name: "kr-NEWS",
                meta: {menu: 'company'},
                component: () => import("../views/lang/Kr/company/news.vue"),
              },
              {
                path: "/kr/company/blog",
                name: "kr-BLOG",
                meta: {menu: 'company'},
                component: () => import("../views/lang/Kr/company/blog.vue"),
              },
              {
                path: "/kr/company/partners",
                name: "kr-PARTNERS",
                meta: {menu: 'company'},
                component: () => import("../views/lang/Kr/company/partners.vue"),
              },
              {
                path: "/kr/company/contact",
                name: "kr-CONTACT",
                meta: {menu: 'company'},
                component: () => import("../views/lang/Kr/company/contact.vue"),
              },
              {
                path: "/kr/company/article/:id", 
                name: "kr-NEWSDETAIL",
                meta: {menu: 'company'},
                component: () => import("../views/company/news-detail.vue"),
              }
            ]
          },
        ],
      }
    ],
  },
  {
    path: "/teamservice",
    name: 'TEAMSERVICE',
    component: () => import("../views/common/teamService.vue")
  },
  {
    path: "/privacypolicy",
    name: 'PRIVACYPOLICY',
    component: () => import("../views/common/privacypolicy.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

export default router;
export { routes };
