const Kr = {
    nav: {
      home: "首页",
      product: "제품",
      pricing: "비용 문의",
      solutions: "솔루션",
      company: "회사",
      sub_products: "제품",
      sub_features: "功能特性",
      sub_cdp: "고객 데이터 플랫폼",
      sub_iqplatform: "IQ 플랫폼",
      sub_cds: "크로스 채널 데이터",
      sub_ccm: "크로스 채널 캠페인 매니지먼트",
      sub_channel: "渠道管理",
      sub_campaign: "营销管理",
      sub_audience: "受众管理",
      sub_data: "数据管理",
      sub_about_us: "기업정보",
      sub_pres_media: "뉴스룸",
      sub_blog: "블로그",
      sub_partners: "파트너",
      sub_contact_us: "제품 문의",
      sub_retail: "소매업 및 유통",
      sub_game: "게임",
      sub_financial_services: "금융",
      sub_travel: "여행",
      sub_media: "미디어",
      sub_automotive: "자동차 산업",
      btn: "무료로 체험하세요 "
    },
    footer: {
      tel: 'Tel',
      product: "제품",
      pricing: "비용 문의",
      solutions: "솔루션",
      company: "회사",
      sub_cdp: "고객 데이터 플랫폼",
      sub_iqplatform: "IQ 플랫폼",
      sub_cds: "크로스 채널 데이터",
      sub_ccm: "크로스 채널 캠페인 매니지먼트",
      sub_features: "Features",
      sub_pricing: "비용 문의",
      sub_retail: "소매업 및 유통",
      sub_game: "게임", 
      sub_financial_services: "금융",
      sub_travel_and_hotel: "여행",
      sub_media: "미디어",
      sub_automotive: "자동차 산업",
      sub_eduration: "Eduration",
      sub_about_us: "기업정보",
      sub_pres_media: "뉴스룸",
      sub_blog: "Blog",
      sub_partners: "파트너",
      sub_contact_us: "제품 문의",
      sign_message: '최신 월드스파이어 뉴스에 대한 이메일을 구독하십시오.',
      email: '이메일',
      subscribe: '신청',
      web_info_copyright: ' Copyright © 2022 Chinapex.',
      web_info_rights: 'All Rights Reserved.',
      web_info_privacy_policy: 'Privacy policy',
      web_info_terms: 'Terms of Service',
      thanks: "고마워요 고마워요!"
    },
    home: {
      banner_message: "차세대 AI 기반 고객 데이터 애플리케이션",
      banner_sub_message: "더 지능적으로, 더 실시간으로, 더 민첩하게",
      banner_btn: "데모 문의",
      solution_title: "기업을 위한 엔드 투 엔드 솔루션",
      cdp: "고객 데이터 플랫폼",
      cdp_description: "지능형 고객 데이터 플랫폼(Customer Data Platform)를 통해 여러 사업부에 데이터를 공급하여 전체 고객 라이프사이클에 걸쳐 AI 기반 운영 및 글로벌 인텔리전스 마케팅을 가능하게 합니다. ",
      iq: "IQ 플랫폼",
      iq_description: "글로벌 마케팅 및 고객 통찰력 시나리오를 기반으로 엔터프라이즈 수준의 예측 AI 응용 프로그램 플랫폼은 고객 데이터 자산의 가치를 심층적으로 탐색하고 극대화하도록 설계되었습니다.",
      cds: "크로스 채널 데이터 스위치 ",
      cds_description: "크로스 채널 데이터 스위치는 채널 데이터를 연결하고 기업이 신뢰할 수 있는 데이터를 기반으로 최대의 정확성, 일관성 및 컨텍스트를 바탕으로 빠르고 자신 있는 결정을 내릴 수 있도록 합니다.",
      ccm: "크로스채널 캠페인 매니지먼트",
      ccm_description: "기업 수준 광고 데이터 통합 ​​관리 플랫폼, 광고 데이터 관리 및 데이터 분석을 지원하여 교차 채널 마케팅 계획, 실행 및 광고 효과 평가 실현 가능하게 합니다.",
      data_pipeline: "데이터 파이프라인",
      module: "모듈",
      module_channel: "채널 매니저",
      module_channel_text1: '채널 커넥션',
      module_channel_text2: '성능 개요',
      module_campaign: "캠페인 매니저",
      module_campaign_text1: '룩어라이크 캠페인',
      module_campaign_text2: '리타게팅 캠페인',
      module_campaign_text3: '예산 할당',
      module_campaign_text4: '마케팅 인텔리전스',
      module_audience: "오디언스 매니저",
      module_audience_text1: '고객 프로파일',
      module_audience_text2: '채널 오디언스',
      module_audience_text3: '사용자 지정 대상',
      module_audience_text4: '채널 레이블',
      module_audience_text5: '사용자 지정 라벨',
      module_audience_text6: '라벨링 인텔리전스',
      module_data: "데이터 마스터",
      module_data_text1: 'One ID ',
      module_data_text2: '데이터 통합',
      module_data_text3: '데이터 웨어하우스',
      module_data_text4: '컨센트 매니지먼트 ',
      customer: "고객 브랜드",
      ak_dec: "앤커는 세계 최고의 기술 브랜드로서 전 세계 사용자로부터 좋은 평판을 얻었으며 이는 CDP와 같은 디지털 서비스 및 제품을 포함한 데이터 자산 관리에 대한 장기 투자와 밀접한 관련이 있습니다. 앞으로 더 많은 마케팅 시나리오에서 디지털 도구의 도움으로 브랜드 가치와 비즈니스 규모의 두 배 성장을 계속해서 실현할 것입니다.",
      ch_dec: "월드스파이어의 AI 기반 데이터 솔루션 CDP를 배포함으로써 춘추항공은 1천만 수준의 회원 데이터, 1억 수준의 비행 데이터 및 방문자 데이터를 통합하여 데이터 소스 전반에 걸쳐 360° 사용자 초상화를 형성하고 항공권에 대한 개인화된 추천을 추출였습니다. 그로인해 이 모델은 고객 재구매율을 0.7%에서 6.7%로 높였습니다.",
      sb_dec: "스타벅스는 CDP 구축을 통해 제품 정보, 회원 정보 및 주문 정보와 같은 다채널 및 다차원 데이터를 공개하고 통합, 사용자 라벨 초상화를 형성하고 음식 속성 일치에 대한 사용자의 선호도에 대한 통찰력을 얻었습니다. 개인화 마케팅, 알고리즘 모델을 기반으로 실시간으로 음료-식품 구매 믹스를 최적화하고 쿠폰을 다이나믹하게 전송하여 최종 관련 판매 추천의 구매율이 46% 증가하고 단가가 크게 상승했습니다.",
      mk_dec: "마이클 코어스는 월드스파이어의 AI 알고리즘 모델을 기반으로 서로 다른 범주 간의 관계를 파악하고 교차 판매 전략을 생성하며 교차 채널 맞춤 추천을 제공합니다. 개인화 추천 클릭률은 270%, 교차 판매 효율성은 21% 증가했습니다."
    },
    form: {
      message: "저희는 항상 당신의 곁에 있습니다.",
      firstName: '이름',
      lastName: '성',
      email: '이메일',
      phone: '전화번호',
      company: '회사명',
      job: '직함',
      country: '국가',
      comments: '문의사항',
      btnMessage: '문의',
      thanks_message1: "Thank you for getting in touch, ",
      thanks_message2: "we will reach back to you in a short time.",
    },
    product: {
      cdp: {
        banner_title: 'CDP',
        banner_sub_title: '고객 데이터 플랫폼',
        banner_description: "월드스파이어는 지능적인 자산 관리와 고객 라이프사이클 가치 향상을 통해 기업들에게 데이터의 잠재력을 최적화할 수 있는 능력을 제공합니다. 맞춤형 분석과 BI 및 AI 기반 통찰력을 갖춘 플랫폼의 강력한 데이터 수집, 처리 및 거버넌스 기능을 통해 원활한 데이터 애플리케이션과 지능형 폐쇄 루프 글로벌 마케팅 시스템이 가능해집니다. 360도 고객 통찰력, 스마트 마케팅 및 개인화를 통해 데이터 기반 마케팅의 자유를 누리십시오.",
        banner_btn: "데모 문의",
        module: "모듈",
        module_intelligent: "인텔리전트 마케팅",
        module_intelligent_dec: "개인화된 실시간 데이터 기반 지능형 마케팅 구현",
        module_insight: "고객 인사이트 및 BI",
        module_insight_dec: "대규모 고객 데이터, 맞춤형 통찰력 및 BI의 다차원 분석 가능",
        module_lifecycle: "고객 라이프사이클 매니지먼트",
        module_lifecycle_dec: "모든 단계에서 고객 경험을 최적화하고 고객 라이프사이클 전반에 걸쳐 데이터 가치를 관리합니다.",
        module_loyalty: "로열티 및 재구매",
        module_loyalty_dec: "고객 충성도, 재구매율, LTV 개선 및 해지율 감소",
        module_service: "인텔리전트 고객 서비스",
        module_service_dec: "실시간 통찰력을 사용하여 더 나은 고객 서비스 제공",
        data_pipeline: "CDP 데이터 파이프라인",
        features: "기능",
        customer_profile: "고객 프로파일",
        customer_profile_description: "CDP는 기업이 다양한 플랫폼 및 채널에서 고객과 상호 작용하는 모든 데이터를 통합하고 데이터 처리를 통해 지속적으로 업데이트되는 고객의 파노라마 초상화를 형성하여 기업이 고객 여정을 이해하고 모든 부서에 귀중한 정보를 제공하여 성능 향상을 가능하게 합니다.",
        custom_audiences: "맞춤 타겟",
        custom_audiences_description: "표준을 준수하는 고객 행동 데이터 및 기타 속성 데이터를 기반으로 기업은 타겟 고객을 맞춤화한 다음 푸시 알림과 이메일을 개인화하여 청중을 위한 맞춤 서비스를 맞춤화할 수 있습니다. ROI를 개선하는 동시에 우수한 고객 경험을 통해 더 깊은 커뮤니케이션과 고객 관계를 구축할 수 있습니다.",
        custom_labelling: "커스텀 레이블링",
        custom_labelling_description: "CDP는 속성을 기반으로 한 맞춤형 태그 트리거 구성을 지원하고 기계 학습 기술을 결합하여 속성 정규화를 계산하고 형성된 태그 데이터를 데이터 센터에 저장하여 개별 및 사전 정의된 작업을 통합합니다. 재구성된 데이터와 맞춤형 레이블을 결합하면 보다 안정적이고 빠른 데이터 분석이 가능합니다. 동시에 데이터의 구조화된 처리를 기반으로 데이터는 GDPR, HIPAA 및 CCPA 표준을 엄격하게 준수합니다.",
        one_id: "ONE ID",
        one_id_description: "ONE ID는 모든 플랫폼과 채널에서 알려진 사용자 ID와 익명 사용자 ID의 연결을 지원합니다. 기계 학습 기능을 사용하여 사용자 식별 정규화 예측을 위한 실명 및 익명 사용자 행동 데이터를 통합하면 최고의 정확도로 글로벌 사용자 관계를 열어 강력한 타겟팅 및 개인화된 상호 작용을 지원하고 모든 장치, 접점 및 채널에서 일관된 프로비저닝을 달성할 수 있습니다. ",
        data_integration: "데이터 통합",
        data_integration_description: "보다 선도적인 기술 서비스 제공업체와의 심층적인 통합 및 협업을 지원하고, 기업이 기술 활용도와 가치를 개선하고, 옴니채널에서 일관된 고객 경험을 가능하게 합니다. 또한, 효과적인 데이터 통합은 수동 데이터 수집 및 분석으로 인한 인건비와 오류 비용을 줄이고 과학적이고 효율적인 방법을 기반으로 보다 가치 있는 데이터 통찰력과 비즈니스 인텔리전스를 제공합니다.",
        data_warehouse: "데이터 웨어하우스 ",
        data_warehouse_description: "기업이 데이터 사일로를 제거하는 데 도움이 될 수 있습니다. 플랫폼과의 통합을 통해 기업의 옴니채널 및 모든 접점에 대한 데이터 수집, 통합, 통합 및 분석을 통해 통합 데이터 자산을 구축하고 데이터 품질 보장을 기반으로 보다 과학적이고 민첩한 의사 결정을 추진합니다. ",
        worldspire: 'WHY Worldspire ?',
        ws_sub_title: "We're everywhere you need us to be",
        ws_sub_message: 'We are a global company with strategically located offices, personnel, and technology infrastructure, allowing us to offer our customers capabilities for managing data based on geographic considerations.',
        data: 'Real-time Data',
        data_sub_title: 'Real-time Data',
        data_sub_message: '',
        business: 'Built for the entire business',
        business_sub_title: 'Built for the entire business',
        business_sub_message: '',
        security: 'Privacy and Security',
        security_sub_title: 'Privacy and Security',
        security_sub_message: '',
        team: 'Our team is your team',
        team_sub_title: 'Our team is your team',
        team_sub_message: '',
      },
      iq: {
        banner_title: 'IQ 플랫폼',
        banner_description: "Uni 마케팅을 위한 최고의 AI 플랫폼 중 하나는 딥 러닝, 연합 학습 및 NLP와 같은 기술을 활용하여 고객 데이터 및 데이터 자산의 가치를 극대화하고 고객 수명 주기 시나리오에 대한 심층적이고 예측 가능한 통찰력을 생성합니다. 많은 B2C 기업이 IQ의 기술을 활용하여 고객 라이프사이클 관리 및 지능형 운영에서 우위를 점하고 있습니다. 결과에 기반한 IQ플랫폼을 통해 팀은 핵심 성과 지표를 식별하고 최적화할 수 있습니다.",
        banner_btn: '데모 문의',
        benefits: "제품 장점",
        benefits_title1: "방대한 데이터, 고품질 성능",
        benefits_dec1: "고객 데이터 센터 및 데이터 개발 센터와 ​​결합하면 대용량 데이터를 AI 모델 컴퓨팅 작업과 병렬로 쉽고 효율적으로 처리, 변환 및 운영할 수 있습니다.",
        benefits_title2: "비지니스 가치를 제공하기 위한 신속한 배포",
        benefits_dec2: "IQ 플랫폼에는 20개 이상의 Uni 마케팅 시나리오에 대한 모델 제품이 포함되며, 구현 프로세스는 많은 자산을 배포할 필요 없이 빠르게 시작하고 비즈니스 가치를 빠르게 추출할 수 있습니다.",
        benefits_title3: "개방형 플랫폼, 유연한 데이터 활용",
        benefits_dec3: "모델의 인사이트 출력은 여러 시나리오, 채널 또는 도구로 구현됩니다. 비즈니스 담당자는 마케팅 연락처, 스마트 레이블 생성, API 데이터 인터페이스 출력 및 기타 시나리오에 대한 시각적 보고서를 볼 수 있습니다.",
        benefits_title4: "데이터 시각화 + BI",
        benefits_dec4: "시나리오 기반 모델 데이터 통찰력 및 비즈니스 인텔리전스. 사용자는 고객 데이터 센터의 데이터 보고서 개요를 결합하고 IQ 통찰력을 사용하여 내부 다중 역할 사용자에게 반영할 수 있습니다. ",
        features: 'FEATURES',
        sub_title: 'IQ Platform',
        sub_dec: 'IQ is a leading intelligent AI platform for Uni marketing, enabling organizations to maximize the value of their customer data and data assets, creating deep and predictive insights into a range of customer life-cycle scenarios. Leveraging Deep Learning, Federated Learning, NLP, and other technologies, IQ helps B2C companies sharpen their technology-driven edges in customer lifecycle management and intelligent operations. IQ is results-driven; it helps business teams optimize critical core business KPIs'
      },
      cds: {
        banner_title: 'CDS',
        banner_sub_title: "크로스 채널 데이터",
        banner_description: "CDS는 채널 데이터를 연결하고 기업이 신뢰할 수 있는 데이터를 기반으로 최대한의 정확성, 일관성 및 컨텍스트를 바탕으로 빠르고 자신 있는 결정을 내릴 수 있도록 지원합니다. 사용 중인 모든 채널(Google, Facebook, Tiktok, Bing 및 기타 여러 소스)에서 사용자 관련 데이터를 쉽게 가져오고 집계합니다.",
        banner_btn: '데모 문의',
        data_pipeline: 'CDS 데이터 파이프라인',
        features: "기능",
        features_title1: "채널 커넥션",
        features_title2: "성능 개요",
        features_title3: "채널 잠재고객 ",
        features_title4: "채널 레이블",
        features_dec1: "월드스파이어의 데이터 통합은 테스트를 거쳐 안정적이므로 API 업데이트나 할당량 제한에 대해 걱정할 필요가 없습니다. 픽셀 추적을 서버 측으로 이동하고 고객이 누구인지 더 잘 이해하고 채널 전반에 걸친 전체 여정을 살펴봅니다.",
        features_dec2: "글로벌 데이터 통합을 기반으로 맞춤형 보고서 통계, 크로스 채널 데이터 시각화, 셀프 서비스 마케팅 효과 분석, 대상 고객의 교차 채널 행동 선호도에 대한 통찰력을 지원하고 기업 의사 결정을 위한 데이터 지원을 제공합니다. .",
        features_dec3: "실시간 업데이트된 채널 데이터를 기반으로 정확한 잠재고객을 생성합니다. 광고 전략 및 교차 채널 개인화 마케팅에 의해 촉발된 고객 데이터는 실시간으로 업데이트되어 사용자 데이터 플랫폼에 보고됩니다.",
        features_dec4: "완전히 구성 가능한 채널 레이블 트리거는 적절한 동의를 받은 경우에만 실행됩니다."
      },
      ccm: {
        banner_title: 'CCM',
        banner_sub_title: "크로스 채널 캠페인 매니지먼트",
        banner_description: "광고주 및 대행사는 월드스파이어의 웹 기반 교차 채널 캠페인 관리 시스템을 통해 광고 데이터를 관리할 수 있으며, 이를 통해 데이터, 분석, 세분화를 관리하고 계획, 실행 및 측정에 워크플로우 도구를 활용할 수 있습니다. 예산 할당, 캠페인 계획 및 성과 보고와 같은 기능을 통해 프로그램 목표를 달성할 수 있습니다. 월별, 분기별 또는 지정된 기간 동안의 지출을 추적하고 페이스 모듈을 통해 입찰 목표를 조정할수있습니다.",
        banner_btn: '데모 문의',
        data_pipeline: 'CCM 데이터 파이프라인',
        data_flow: 'CCM 데이터 흐름',
        features: "기능",
        features_title1: "룩어라이크 캠페인",
        features_dec1: "룩어라이크 캠페인은 우수 고객의 데이터를 검사후 자사 데이터를 사용하여 최고의 잠재 고객을 타겟팅하는 데 도움이 됩니다. 모든 속성을 기반으로 사용할 수 있는 최고의 고객 목록을 생성하여 더 높은 품질의 고객을 효과적이고 효율적으로 타겟팅하고 획득하여 잠재고객을 개선합니다.",
        features_title2: "리타게팅 캠페인",
        features_dec2: "실시간 정확한 데이터 트래킹을 기반으로 역내외 데이터를 통합 데이터 기반을 구축하여 잠재적인 고전환 사용자의 행동 특성을 정확히 식별하고, 페이스북 사용자 데이터 및 강력한 기계학습 능력과 같이 소셜 미디어를 결합하여 높은 구매 의향자를 선정하거나 의도하지 않은 사람들을 배제하고, 재전송하여 ROI 투입을 극대화합니다.",
        features_title3: "예산 할당",
        features_dec3: "분할된 방식으로 예산 분배 프로세스를 간소화하고, 지능화, 가시화, 실시간화된 예산 관리를 실현함으로써, 명확한 전략 계획 아래, 과학적인 예산 분배 방식과 전략을 통해, 기업이 계량적으로 측정할 수 있는 상업 효과 KPI 지표를 최적화하여 전략 목표의 실현에 도움을 준다.",
        features_title4: "마케팅 인텔리전스",
        features_dec4: "가능성 모델을 사용하여 고객의 습성에 대한 통찰력과 명확성을 확보합니다. 당신의 고객이 구매, 전환 또는 전액 지불 할것인지 크로스채널 캠페인 매니지먼트의 고급 모델을 사용하면 고객에게서 이러한 특성을 확인할 수 있습니다. 머신 러닝을 사용하여 이러한 특성의 패턴을 기반으로 고객을 그룹화할수도 있습니다. 고객의 행동, 성격 및 계절성과 같은 데이터를 고려하여 표면 마케팅을 통해 개인 수준에서 권장 사항을 제공합니다. 고객에게 할인 혜택과 차선책 추천을 제공하여 고객 지출을 극대화하고, 마케팅 담당자에게 캠페인 추천을 제공할 수도 있습니다. ",
      },
      worldspire: {
        title: "왜 월드스파이어인가? ",
        sub_title1: "월드스파이어는 고객이 필요로 하는 어디든지 있습니다. ",
        sub_title2: "실시간 데이터",
        sub_title3: "시장에 대한 깊은 통찰력",
        sub_title4: "마케팅 전략 최적화",
        sub_title5: "기업의 역량 강화",
        sub_title6: "개인 정보 및 보안",
        sub_title7: "전담 서비스 팀",
        sub_dec1: "월드스파이어는 고객이 필요로 하는 곳이면 어디든지 있습니다. 편리한 지리적 환경, 우수한 직원 및 탄탄한 기술력을 갖춘 글로벌 기업으로 다양한 지역의 고객에게 데이터 관련 서비스를 제공하는 데 전념하고 있습니다.",
        sub_dec2: "월드스파이어 플랫폼은 실시간 데이터 수집, 표준화, 변환, 강화 및 활성화를 통해 고객의 경험과 요구사항의 변화에 대응합니다.",
        sub_dec3: "월드스파이어는 고객의 온라인 행동 방식에 대한 정보 및 분석을 제공하고 인구 통계를 정의하며 이 정보가 고객의 경험을 어떻게 개선할 수 있는지를 인식하도록 지원함으로써 고객을 전문가로 만듭니다. ",
        sub_dec4: "오늘날 기업을 위한 전반적인 디지털 마케팅 전략은 그 어느 때보다 중요합니다. 기업은 데이터를 통해 마케팅 활동 및 전략에 대한 타겟 오디언스의 피드백을 파악하여 보다 효과적이고 개인화된 광고를 만들 수 있습니다.",
        sub_dec5: "월드스파이어는 기업의 모든 팀이 데이터를 수집, 이해및 활성화할 수 있도록 합니다.",
        sub_dec6: "데이터 보안 및 고객 개인 정보 보호는 항상 월드스파이어 비즈니스 개발의 핵심이었습니다. 현재 ISO 27001, ISO 9001 인증을 획득했으며 GDPR, CCPA, LGPD 및 HIPAA 인증 표준을 준수합니다.",
        sub_dec7: "경험이 풍부한 고객 성공 팀과 디지털 마케팅 전문가가 귀사의 비즈니스가 고객 경험을 개선하고 비즈니스 목표를 달성할 수 있도록 지원합니다.",
      }
    },
    company: {
      about: {
        banner_title: '기업정보',
        text1: "AI와 데이터 과학을 핵심으로 하는 차세대 지능형 비즈니스 애플리케이션 구축",
        text2: "월드스파이어는 중대형 B2C 기업에 차별화된 방식으로 AI 기반 고객 데이터 기술 솔루션을 제공하는 데 전념하는 데이터 기술 및 AI 기업으로, 기업이 고객 통찰력, 개인화된 마케팅, 고객 수명 주기 가치를 포함하여 고객 데이터와 관련된 애플리케이션 시나리오를 최적화할 수 있도록 지원합니다. 관리, 고객 경험 개선 등 월드스파이어는 수백 개의 국내외 중견 기업에 서비스를 제공하고 있으며 고객은 소매, 자동차, 금융, 관광, 교육 및 훈련과 같은 업종을 포함하고 있습니다.", 
        text3: 'We combine cutting-edge big data and AI to tackle problems in areas of marketing, personalization, and customer analytics.',
        text4: 'We have served 300+ mid to large global and China enterprises in sectors of retail, automotive & mobility, travel, finance and ecommerce.',
        awards_title: '유명 상',
        awards_dec1: 'The only Chinese company listed on the 2018 Red Herring TOP 100 Global (Big Data)',
        awards_dec2: '2018 Forbes Top 50 Potential Chinese Companies',
        awards_dec3: '2018 Top 100 Artificial Intelligence Companies',
        awards_dec4: '2018 Top 50 Chinese Unicorns in Big Data Industry',
        awards_dec5: 'Selected as a Tencent AI Accelerator Phase III company among 1500+ competitors',
        awards_dec6: 'Selected as one of the 15 Microsoft Accelerator companies among 1000 competitors',
        certification_title: 'ISO9001 certification and high-tech enterprise certification',
        certification_dec1: 'Quality management system certification',
        certification_dec2: 'National high-tech enterprise',
        patents_title: '50+ product-related computer software works and patents',
        patents_item1: 'Enterprise Big Data Encryption Software',
        patents_item2: 'Customer Tag Analysis Software',
        patents_item3: 'APEX Robotics Learning Service Software',
        patents_item4: 'Customer Data Management Platform Software',
        patents_item5: 'Big Data Report Management Software',
        patents_item6: 'APEX China Digital Marketing Platform Software',
        patents_item7: 'Data Analysis Funnel Software',
        patents_item8: 'Enterprise-level Intelligent Data Platform',
        patents_item9: 'APEX API Network Management Software',
        patents_item10: 'User Marketing Lens Analysis Software',
        patents_item11: 'APEX Code Management System',
        patents_item12: 'APEX Code Management System',
        city1: 'SHANGHAI',
        city2: 'NEW YORK',
        city3: 'SAN FRANCISCO',
        city4: 'ATLANTA',
        city5: 'SEOUL',
        city6: 'LONDON',
        city7: 'BARCELONA',
        city8: 'TOKYO',
        // citys:[
        //   'SHANGHAI', 'NEW YORK', 'SAN FRANCISCO', 'ATLANTA', 'SEOUL', 'LONDON', 'BARCELONA', 'TOKYO'
        // ],
        customer: '고객 브랜드',
        org_title1: "월드스파이어는 국제 표준 프라이버시 법과 규범을 철저히 준수합니다.",
        org_title2: "",
        org1: "CCPA",
        org2: "GDPR",
        org3: "HIPAA",
        org4: "LGPD",
      },
      contact: {
        banner_title: '문의하기',
        city1: '홍콩 본사',
        city2: '심천',
        city3: '북경',
        city4: '실리콘 밸리',
        city5: '상하이',
        city6: '서울',
        addr1: ' 홍콩 에드머럴티 퀸즈웨이 89 리포센터 타워1 1003호 ',
        email1: '이메일: ',
        addr2: '심천 푸티앤구 황강로 사우스업힐 로프트B 6층',
        addr3: '북경시 차오양구 량마치아오 동팡동로19 외교빌딩 브럭D 17층',
        addr4: '캘리포니아 94583 산 래먼 200 더우드로드 111',
        email2: 'E-mail: sf@chinapex.com',
        addr5: '상하이시 홍커우구 1098 동대명로 상하이포인트 902-908 ',
        addr6: '서울시 서대문구 연희로57 41길 케이하우스 101호',
      },
      partners: {
        banner_title: "파트너",
        parteners_title: "파트너 솔루션",
        parteners_dec: "우리는 16+ 주요 해외 디지털 마케팅 플랫폼 및 전자상거래 채널과 협력하여 당신에게 더욱 정확하고 신뢰할 수 있는 데이터 서비스를 제공합니다.",
        join_title: "Join Worldspires's partner",
        join_dec: "Sign up below and we will contact you shortly with more information about our solution partner program.",
        join_btn: "Become a Partner"
      },
      blog: {
        banner_title: "블로그"
      },
      news: {
        banner_title: "뉴스룸",
        read_more: "자세히 보기",
        list: {
          item1: {
            title: "월드스파이어는 디지털 광고 ROI를 개선하기 위해 크로스 채널 데이터 스위치를 출시합니다.",
            content: "팬데믹은 소비 방식과 마케팅 형태를 변화시켰습니다. 디지털 채널은 가장 중요한 마케팅 채널이 되었습니다. 디지털 광고 지출은 2020년 6월부터 2021년 2월까지 14.3% 증가으며 이는 6개월 중 가장 높은 증가율 중 하나입니다. 그러나 이 과정에서, 생성된 데이터를 사용하는 방법, 전체 데이터를 관리하는 방법, 특정 캠페인의 효과를 정의하는 방법이 이슈가 되었습니다.",
          }
        }
      },
      news_detail: {
        banner_title: "뉴스룸",
        detail1: {
          title: "크로스 채널 데이터 를 출시합니다",
          date: "2022-4-1"
        }
      }
    },
    solutions: {
      retail: {
        banner_title: "소매업",
        sub_title1: "챌린지",
        sub_dec1: "많은 브랜드들이 빠르게 변화하는 취향과 기대치, 급속한 기술 변화와 같은 장애물에 직면해 브랜드가 고객에게 탁월한 쇼핑 경험을 제공하는 것이 그 어느 때보다 어려워지고있으며, 특히 개인정보 보호 및 데이터 규제가 증가함 따라 더욱이 어려워지고있습니다.",
        sub_title2: "중요 포인트",
        sub_dec2: "브랜드는 고객을 이해함으로써 쇼핑 경험을 개선할 수 있습니다. 고객을 이해하는 가장 좋은 방법은 데이터를 수집하고 분석하는 것입니다. 고객이 이미 해당 브랜드에 속한 자사 데이터를 통합하기 위해 프로필을 생성하도록 할 수 있습니다. 사용자 프로필을 통해 브랜드는 관련 정보와 개인화된 메시지로 고객을 대상으로 캠페인을 조정하여 고객의 지출을 늘릴 수 있습니다.",
        sub_title3: "지원 방법",
        sub_dec3: "강력한 기본 데이터 처리 기능을 기반으로 하는 월드스파이어는 단편화된 데이터를 통합 및 연결하고, 귀사가 통합되고 사용 가능한 데이터 기반을 구축하도록 지원하고, 통찰력을 기반으로 360도 고객 초상화를 형성하여 고객 경험 및 기타 포괄적인 비즈니스 전략을 안내합니다. "
      },
      game: {
        banner_title: "게임",
        sub_title1: "챌린지",
        sub_dec1: "게임 산업 시장은 지난 15년 동안 빠르게 발전했으며 레저 및 엔터테인먼트 활동에서 전 세계적으로 광범위한 영향을 미치는 거대한 블루오션 시장으로 발전했습니다. 급속한 확장은 많은 기업가의 유입으로 이어졌으며나 게임 산업의 경쟁은 과열 단계에 진입했습니다.",
        sub_title2: "중요 포인트",
        sub_dec2: "게임산업내 몰려드는 계발자들로 인하여 게임산업의 경쟁성이 심화되었고 따라서 사용자를 새로운 게임으로 끌어당기는 것이 점점 어려워지는 추세이다.",
        sub_title3: "지원 방법",
        sub_dec3: "월드스파이어는 게임 회사가 캐릭터 생성, 게임 교육, 앱 구매와 같은 다중 시나리오 데이터를 분석하고 이를 인사이트로 사용하여 플레이어에게 보다 풍부한 경험을 제공할 수 있도록 지원합니다. 동시에 멀티 게임 데이터 접근 서비스를 지원하며, 특정 게임 데이터의 교차 분석을 통해 고객사 플레이어의 게임 선호도를 더 잘 이해할 수 있으며 이를 기반으로 게임 시나리오 및 마케팅 활동을 계획하여 플레이어에게 더 많은 것을 제공합니다.",
      },
      financial: {
        banner_title: "금융",
        sub_title1: "챌린지",
        sub_dec1: "디지털 시대에 경험은 금융 기관의 차별화된 경쟁 우위가 되었습니다. 그러나 고객의 니즈가 개인화, 다양화, 시나리오 기반화되면서 금융기관이 성장을 위한 서비스와 마케팅을 정확히 파악하고 제공하는 것이 어려워지고 있습니다.",
        sub_title2: "중요 포인트",
        sub_dec2: "금융 기관은 데이터를 수집하고 적용함으로써 고객에게 보다 개인화된 디지털 경험을 제공하고 신뢰할 수 있는 전문 고문으로서 고객과 더 깊은 관계를 구축할 수 있습니다.",
        sub_title3: "지원 방법",
        sub_dec3: "고객 데이터 자산을 축척하고 활성화하면 금융 서비스 기관이 상호 작용을 개선하고 고객에게 최고의 경험을 제공할 수 있습니다. 현재 10개 이상의 주요 금융 서비스 및 최고의 지불 서비스 회사가 월드스파이어를 선택하여 포괄적인 고객 통찰력을 얻고 고객 데이터 수집, 정리 및 활성화를 통해 고객 경험 개선을 강화하고 있습니다.",
      },
      media: {
        banner_title: "미디어",
        sub_title1: "챌린지",
        sub_dec1: "사용자는 하루 평균 최대 12시간 동안 다양한 기기에서 미디어를 사용합니다. 이러한 고강도 연결은 사용자가 보다 완전한 경험을 기대하게 합니다. 이를 위해 미디어는 다양한 기기에서 사용자를 식별하고 원활한 경험을 제공해야 합니다. 이는 미디어 기업, 특히 모바일 및 OTT 채널을 통해 고객에게 다가가려는 기업에게 기회이자 도전입니다.",
        sub_title2: "중요 포인트",
        sub_dec2: "사용자가 다양한 장치와 채널에서 빈번하게 전환하기 때문에, 미디어는 더 이상 기존의 시스템 플랫폼을 기반으로 사용자가 누구인지, 그리고 어떻게 언제 접근할 수 있는지 알 수 없으며, 마케팅, 제품 및 시스템 간의 관계를 측정하여 효과적인 마케팅 성장 전략을 세우기가 어렵습니다.이를 위해 보다 많은 전향적인 사고를 가진 매체들이 사용자 데이터를 통합하고 활성화하기 위해 고객 데이터 플랫폼을 채택하기 시작했습니다.",
        sub_title3: "지원 방법",
        sub_dec3: "올바른 CDP를 찾기 위해 마케터는 현재 데이터 성숙도와 데이터 사용 방법, 미래 목표 및 이러한 목표를 달성하기 위해 필요한 데이터를 고려해야 합니다. 데이터 성숙도 수준은 기초, 통찰력 및 실험, 옴니채널 참여, 지속적인 최적화의 네 가지 성숙도 수준으로 가장 낮은 수준에서 가장 성숙한 수준으로 나눌 수 있습니다. 월드스파이어는 모든 수준의 사용 사례를 안내하고, 상황과 관련된 CDP 기능을 결정하고, 현재 및 미래의 비즈니스 요구 사항을 충족하는 데 도움이 될 CDP를 찾는 데 도움이 됩니다.",
      },
      travel: {
        banner_title: "관광업",
        sub_title1: "챌린지",
        sub_dec1: "코로나 대유행의 영향으로 관광 산업이 빠르게 폐쇄되었습니다. 산업의 회복을 가속화하기 위해 호텔과 항공사와 같은 기업은 생존과 더 나은 삶을 위해 마케팅 전략을 변경하고 고객과의 연결을 심화해야 합니다.",
        sub_title2: "중요 포인트",
        sub_dec2: "오늘날의 여행자는 여행 경험에 더 많은 관심을 기울입니다. 이를 위해 우수하고 지속적인 경험은 전환율, 충성도 및 관광 회사의 교차 판매를 촉진하여 지속 가능한 성장을 가능하게 하여야 합니다. ",
        sub_title3: "지원 방법",
        sub_dec3: "훌륭한 고객 경험은 데이터를 기반으로 해야 합니다. 월드스파이어는 호텔, 항공사 및 기타 회사가 실시간으로 데이터를 수집, 통합, 연결 및 활성화하여 전체 범위의 고객 통찰력을 형성하도록 지원하여 고객 경험 개선을 강화합니다.",
      },
      automotive: {
        banner_title: "자동차 산업",
        sub_title1: "챌린지",
        sub_dec1: "자동차 회사는 딜러, 자체 앱, 공식 웹사이트, 광고, 마케팅 도구 및 기타 채널에서 충분한 자금과 풍부한 데이터를 보유하고 있습니다. 그러나 데이터는 다양한 시스템에 흩어져 있기 때문에 종종 경험이나 제한된 데이터를 기반으로 마케팅 전략을 세울 수 밖에 없습니다.",
        sub_title2: "중요 포인트 ",
        sub_dec2: "마케팅 ROI를 개선하기 위해 자동차 회사는 1자 고객 데이터를 수집하고 이를 3자 데이터와 통합하여 소비자에 대한 전체적인 관점에서 개인화된 고객 경험을 제공해야 합니다. 그러나 데이터가 다른 채널, 다른 부서 및 다른 시스템에 흩어져 있기 때문에 통합 관리 및 분석을 위한 효과적인 직렬화 메커니즘과 통합 식별 알고리즘이 부족하여 데이터 사일로가 발생합니다.",
        sub_title3: "지원 방법",
        sub_dec3: "월드스파이어 CDP는 다른 데이터 소스를 연결할 수 있는 플러그 앤 플레이, 빠른 구현 솔루션을 자동차 회사에 제공하여 데이터를 다른 브랜드로 전송할 수 있으므로 마케팅 효율성을 향상시킬 수 있습니다. CDP 솔루션을 통해 자동차 회사의 고객은 구매 결정 가능성이 높은 고객 그룹에 대한 인사이트를 얻을 수 있고, 이러한 고객의 경험을 최적화하여 전환율을 높일 수 있습니다. 행동을 기반으로 다양한 잠재고객을 생성하고 전환, 수익 성장 및 전환 효율적인 광고 전달을 유도하는 개인화된 커뮤니케이션을 제공합니다. 통합된 고객 데이터와 정확한 예측 모델링은 성사율과 마케팅 ROI를 개선하고 자동차 제조업체가 비용을 절감하며 마케팅 및 영업 팀 간의 협업 및 커뮤니케이션을 더욱 강화하는 데 도움이 됩니다. 현재 고객은 애플리케이션 시나리오와 데이터 가치를 지속적으로 확장하고 있습니다. 판매 후 데이터를 사용하여 고객 요구 피드백에 대한 통찰력을 얻고 새로운 제품 및 서비스를 설계하여 수익을 늘리고 고객 브랜드 충성도를 강화합니다. 디지털화가 자동차 회사에 가져온 가장 큰 변화는 360도 고객 초상화가 있을 때 고객 경험의 가능성이 무한하다는 것입니다.",
      }
    },
    pricing: {
      banner_title: "요금제를 비교하여 선택하세요.",
      banner_text: "성장에 따라 계획이 확장됩니다.",
      free: {
        title: "무료로 체험하세요",
        btn: "무료 계정을 생성하십시오.",
        price: "$0/1개월",
        sub_title: "플랜", 
        text1: "1개월 1,000 사용자 포함",
        text2: "2채널",
        text3: "300+ Integrations",
      },
      team: {
        title: "팀",
        btn: "무료로 체험하세요 ",
        price: "$80/1개월 부터",
        sub_title: "무료 프로그램 추가", 
        text1: "1개월 10,000 사용자 포함",
        text2: "무제한 채널",
        text3: "무제한 채널",
      },
      business: {
        title: "비지니스",
        btn: "데모 문의",
        price: "맞춤 플랜",
        sub_title: "팀 콘텐츠 추가", 
        text1: "맞춤형 용량",
        text2: "고객의 단일화",
        text3: "데이터 거버넌스",
        text4: "고급 역할 및 권한",
        text5: "히스토리 재생",
      },
      compare_plans: '요금제 비교'
    }
  };
  
  export default Kr;
  