const zhCN = {
  nav: {
    home: "首页",
    product: "产品",
    pricing: "价格",
    solutions: "行业解决方案",
    company: "公司",
    sub_products: "产品",
    sub_features: "功能特性",
    sub_cdp: "CDP 客户数据中台",
    sub_iqplatform: "IQ 智能营销平台",
    sub_cds: "CDS 跨渠道数据交换平台",
    sub_ccm: "CCM 跨渠道营销管理平台",
    sub_channel: "渠道管理",
    sub_campaign: "营销管理",
    sub_audience: "受众管理",
    sub_data: "数据管理",
    sub_about_us: "关于我们",
    sub_pres_media: "新闻及传媒",
    sub_blog: "博客",
    sub_partners: "合作伙伴",
    sub_contact_us: "联系我们",
    sub_retail: "零售",
    sub_game: "游戏",
    sub_financial_services: "金融服务",
    sub_travel: "旅游",
    sub_media: "媒体",
    sub_automotive: "汽车",
    btn: "免费试用"
  },
  footer: {
    tel: 'Tel',
    product: "产品",
    pricing: "价格",
    solutions: "行业解决方案",
    company: "公司",
    sub_cdp: "客户数据中台",
    sub_iqplatform: "智能营销平台",
    sub_cds: "跨渠道数据交换平台",
    sub_ccm: "跨渠道营销管理平台",
    sub_features: "功能特性",
    sub_pricing: "价格",
    sub_retail: "零售",
    sub_game: "游戏", 
    sub_financial_services: "金融服务",
    sub_travel_and_hotel: "旅游",
    sub_media: "媒体",
    sub_automotive: "汽车",
    sub_eduration: "教育",
    sub_about_us: "关于我们",
    sub_pres_media: "新闻及传媒",
    sub_blog: "博客",
    sub_partners: "合作伙伴",
    sub_contact_us: "联系我们",
    sign_message: '订阅邮件获取Worldspire最新资讯。',
    email: '邮箱',
    subscribe: '订阅',
    web_info_copyright: ' Copyright © 2022 Chinapex.',
    web_info_rights: 'All Rights Reserved.',
    web_info_privacy_policy: 'Privacy policy',
    web_info_terms: 'Terms of Service',
    thanks: "谢谢!"
  },
  home: {
    banner_message: "下一代AI驱动客户数据应用",
    banner_sub_message: "更智能，更实时，更便捷",
    banner_btn: "免费试用",
    solution_title: "企业级端到端解决方案",
    cdp: "客户数据中台",
    cdp_description: "智能企业级客户数据中台，赋予多业务部门数据及AI驱动的全客户生命周期运营，和全局智能营销",
    iq: "AI智能营销平台",
    iq_description: "企业级预测性AI应用平台，在整个全局营销和客户洞察场景下，深度挖掘以及最大化客户数据资产的价值",
    cds: "跨渠道数据交换平台",
    cds_description: "支持多渠道的数据对接服务，轻松接入来海外渠道的真实数据。基于跨渠道数据的打通与整合，赋能业务做出快速且科学的决定",
    ccm: "跨渠道营销管理平台",
    ccm_description: "企业级广告数据综合管理平台，支持广告数据管理及数据分析，以实现跨渠道营销规划、执行及广告效果评估",
    data_pipeline: "数据通路",
    module: "应用模块",
    module_channel: "渠道管理",
    module_channel_text1: '渠道对接',
    module_channel_text2: '效果总览',
    module_campaign: "营销管理",
    module_campaign_text1: '相似人群营销',
    module_campaign_text2: '重定位营销',
    module_campaign_text3: '预算分配',
    module_campaign_text4: '智能营销',
    module_audience: "受众管理",
    module_audience_text1: '客户画像',
    module_audience_text2: '渠道受众',
    module_audience_text3: '自定义受众',
    module_audience_text4: '渠道标签',
    module_audience_text5: '自定义标签',
    module_audience_text6: '智能标签',
    module_data: "数据管理",
    module_data_text1: '唯一标识',
    module_data_text2: '数据集成',
    module_data_text3: '数据仓库',
    module_data_text4: '隐私管理',
    customer: "OUR CUSTOMER",
    ak_dec: '作为全球领先的科技品牌，安克赢得了来自各地用户的好口碑，这和对数据资产管理的长期投入息息相关，其中也包括CDP等数字化服务及产品。未来，我们将在更多营销场景中借助数字化工具，继续实现品牌价值及业务规模的双增长。',
    ch_dec: '春秋航空通过部署Wordlspire的AI驱动数据解决方案CDP，整合了春秋航空千万级别的会员数据、亿级别乘机数据和访客数据，形成了跨数据源的360°用户画像，提取出航线机票个性化推荐模型将客户复购率从0.7%提升到了6.7%。',
    sb_dec: '星巴克通过CDP的搭建，打通了商品信息、会员信息和订单信息等多渠道多维数据，形成统一的用户标签画像，洞察用户对搭配食品属性的偏好程度，从而实行个性化营销。基于算法模型实时优化饮料-食品购买搭配并动态发送优惠券，最终关联销售推荐的购买率提升46%，客单价提升显著。',
    mk_dec: 'Michael Kors基于Worldspire的AI算法模型挖掘不同品类关联关系，生成交叉销售策略，赋能跨渠道个性化推荐。其个性化推荐点击率提升270%，交叉销售效率提升21%。'
  },
  form: {
    message: "我们随时为您服务",
    firstName: '姓',
    lastName: '名',
    email: '邮箱',
    phone: '电话',
    company: '公司',
    job: '职位',
    country: '国家',
    comments: '留言咨询',
    btnMessage: '提交',
    thanks_message1: "Thank you for getting in touch, ",
    thanks_message2: "we will reach back to you in a short time.",
  },
  product: {
    cdp: {
      banner_title: 'CDP',
      banner_sub_title: '客户数据中台',
      banner_description: '通过智能化管理和运营客户生命周期数据，Worldspire帮助企业最大化挖掘数据资产的潜力与价值。Worldspire CDP拥有强大的数据收集、处理及治理能力，并结合敏捷的自定义分析、BI和AI，将数据洞察无缝应用于业务场景，从而构建了智能全域营销闭环。真正实现了客户360度智能洞察、智能营销及个性化，并最终实现数据驱动的营销自由。',
      banner_btn: '免费试用',
      module: '应用模块',
      module_intelligent: '实时数据采集',
      module_intelligent_dec: '实现个性化实时数据驱动的智能营销',
      module_insight: '客户洞察及数据分析',
      module_insight_dec: '海量用户数据的多维分析，还原用户真实路径、勾勒用户行为画像',
      module_lifecycle: '客户全生命周期管理',
      module_lifecycle_dec: '优化各阶段的客户体验，管理客户生命周期的数据价值',
      module_loyalty: '忠实及复购',
      module_loyalty_dec: '增强客户忠诚度，复购率，全生命周期价值以及降低弃购率',
      module_service: '智能客服',
      module_service_dec: '通过对客户数据的实时洞察改进客户服务',
      data_pipeline: "CDP 数据通路",
      features: '功能特性',
      customer_profile: '客户画像',
      customer_profile_description: 'CDP集成了企业在不同平台及渠道上与客户交互的所有数据，通过数据处理形成一个持续更新的客户全景画像，以帮助企业了解客户旅程，并为所有部门提供有价值的信息，赋能业绩提升。',
      custom_audiences: '自定义受众',
      custom_audiences_description: '基于符合标准的受众行为数据及其他属性数据，企业可自定义目标受众，并依此个性化推送通知及电子邮件，为受众量身定做个性化服务。在提升ROI的同时，能以良好的客户体验建立更深度的沟通和客户关系。',
      custom_labelling: '自定义标签',
      custom_labelling_description: 'CDP支持基于属性配置自定义标签触发器，并结合机器学习技术计算属性归一，将所形成的标签数据储存于数据中心，从而整合、打通针对单个和预先定义的任务。重构后的数据结合自定义标签可以实现更加稳定且快速的数据分析。同时，基于数据的结构化处理，数据严格遵守GDPR、HIPAA和CCPA标准。',
      one_id: '唯一标识',
      one_id_description: 'One ID支持对所有平台及渠道上已知和匿名用户身份进行关联和打通。通过机器学习能力集合实名、匿名用户行为数据进行用户标识归一性预测，最高准确性打通全域用户关系，以支持强大的目标定位和个性化交互，实现在所有设备、接触点和渠道上提供一致的客户体验。',
      data_integration: '数据集成',
      data_integration_description: '支持与更多领先技术服务商深度集成与协同，助力企业提高技术利用率与价值，并赋能全渠道一致的客户体验。此外，有效的数据集成减少了手动数据收集与分析所带来的人力成本与错误成本，基于科学高效的方式提供更有价值的数据洞察和商业智能。',
      data_warehouse: '数据仓库',
      data_warehouse_description: '可以帮助企业摆脱数据孤岛。通过与平台的集成，对企业全渠道、全触点的数据收集、整合、打通与分析，构建统一的数据资产，在保障数据质量与数据完整性的基础上驱动更科学更敏捷的决策。',
      worldspire: 'WHY Worldspire ?',
      ws_sub_title: "We're everywhere you need us to be",
      ws_sub_message: 'We are a global company with strategically located offices, personnel, and technology infrastructure, allowing us to offer our customers capabilities for managing data based on geographic considerations.',
      data: 'Real-time Data',
      data_sub_title: 'Real-time Data',
      data_sub_message: '',
      business: 'Built for the entire business',
      business_sub_title: 'Built for the entire business',
      business_sub_message: '',
      security: 'Privacy and Security',
      security_sub_title: 'Privacy and Security',
      security_sub_message: '',
      team: 'Our team is your team',
      team_sub_title: 'Our team is your team',
      team_sub_message: '',
    },
    iq: {
      banner_title: '智能营销平台',
      banner_description: 'IQ是赋予全域营销领先智能AI平台，使企业能够最大化挖掘客户数据和数据资产的价值，并针对一系列客户生命周期相关场景创造深入且预测性的洞察。IQ利用深度学习、联合学习和NLP等技术，为B2C公司在客户生命周期管理，智能运营方面增加了技术优势。IQ是效果驱动的-即帮助业务团队优化至关重要的核心业务KPI最优化。',
      banner_btn: '免费试用',
      benefits: '产品优势',
      benefits_title1: '海量数据，优质性能',
      benefits_dec1: '结合客户数据中台及数据开发中台（CDP），实现海量数据轻松高性能地处理，转换，以及平行运营AI模型计算任务。',
      benefits_title2: '快速落地，获取业务价值',
      benefits_dec2: 'IQ平台涉及到超过20个全域营销场景的模型产品，实施过程较快且轻量级。让业务人员易于上手，以及能快速提取业务价值。',
      benefits_title3: '开放平台，灵活数据运用',
      benefits_dec3: '模型输出的洞察实现于多个场景、渠道或工具中。业务人员能选择查看可视化报表，用于营销触达，创建智能标签，以及API数据接口输出等场景。',
      benefits_title4: '数据可视化+BI',
      benefits_dec4: '场景化的模型数据洞察以及商业智能。用户可以结合NEXUS客户数据中台的数据报表概览，以及定制IQ的洞察给内部多角色用户体现。',
      features: 'FEATURES',
      sub_title: 'IQ Platform',
      sub_dec: 'IQ is a leading intelligent AI platform for Uni marketing, enabling organizations to maximize the value of their customer data and data assets, creating deep and predictive insights into a range of customer life-cycle scenarios. Leveraging Deep Learning, Federated Learning, NLP, and other technologies, IQ helps B2C companies sharpen their technology-driven edges in customer lifecycle management and intelligent operations. IQ is results-driven; it helps business teams optimize critical core business KPIs'
    },
    cds: {
      banner_sub_title: '跨渠道数据交换平台',
      banner_title: 'CDS',
      banner_description: 'CDS支持多渠道的数据对接服务，轻松接入来自如Google, Facebook, Tiktok, Bing等海外渠道的真实数据。基于跨渠道数据的打通与整合，构建消费者唯一视图，赋能业务做出快速且科学的决定。',
      banner_btn: '免费试用',
      data_pipeline: 'CDS 数据通路',
      features: '功能特性',
      features_title1: '渠道管理',
      features_title2: '效果概览',
      features_title3: '渠道受众',
      features_title4: '渠道标签',
      features_dec1: '拥有经过实战检验的渠道对接功能，支持埋点跟踪、无埋点跟踪、及基于API协议的服务端跟踪，无需担心渠道平台的API更新或数据存取配额限制，全方位洞察跨渠道客户旅程。',
      features_dec2: '在全域数据打通与整合的基础上，支持自定义报表统计、跨渠道数据可视化、自助式营销效果分析，洞察目标受众跨渠道行为偏好，为企业决策提供数据支持。',
      features_dec3: '基于实时更新的渠道数据，创建精准受众人群。由广告策略触发及跨渠道个性化营销产生的客户数据将实时更新上报至用户数据平台。',
      features_dec4: '配置渠道标签触发器，仅在授权后生效触发标签的生成机制。'
    },
    ccm: {
      banner_title: 'CCM',
      banner_sub_title: '跨渠道营销管理平台',
      banner_description: 'CCM是面向广告商和代理商的广告数据管理平台。作为企业级营销工具，支持客户数据管理、数据分析及用户分层，以实现跨渠道营销规划、执行及广告效果评估。CCM采用前沿的机器学习等技术能力，满足广告预算智能分配、活动策略规划、广告效果报告等业务需求，帮助企业目标，数字化构建广告营销管理体系。其中Pace模块可基于月、季或自定义时间段调整竞价目标，实时追踪效果，赋能品牌营销力提升与业务增长效率。',
      banner_btn: '免费试用',
      data_pipeline: 'CCM 数据通路',
      data_flow: 'CCM 数据流',
      features: '功能特性',
      features_title1: '相似人群广告',
      features_dec1: ' 将已转化人群作为种子人群，进行标签细分和数据建模，经机器学习后挖掘基于一方数据的高价值标签组，在全网流量中识别并触达更多符合相同标准的高价值人群，赋能多媒介和渠道客户的精准触达与效果评估。',
      features_title2: '重定向广告',
      features_dec2: '基于实时准确的数据跟踪，打通站内外数据构建统一的数据基础，由此精准识别潜在高转化用户的行为特征，并结合社交媒体如Facebook用户数据及强大的机器学习能力最大程度圈选高购买意向人群或排除无意向人群，再次触达，最大程度提升投放ROI。',
      features_title3: '智能预算分配',
      features_dec3: '采用分而治之的方式简化预算分配流程，实现智能化、可视化、实时化的预算管理，从而明晰战略规划下，科学的预算分配方式与策略，助力企业优化可量化衡量的商业效益KPI指标，驱动战略目标的实现。',
      features_title4: '营销智能',
      features_dec4: '作为CCM的高级模块 ，其拥有如Likelihood 模型等强大的机器学习算法，可准确识别客户的关键细分市场，创造基于行为、角色、季节等更多增长机会。同时，场景化机器学习与AI能力，将产品与业务战略相结合，赋能于产品个性化C2M、预测性客户洞察等场景，基于输出科学的营销策略以帮助企业构建核心竞争力。',
    },
    worldspire: {
      title: '为什么选择Worldspire？',
      sub_title1: '我们在你需要的任何地方',
      sub_title2: '激活实时数据',
      sub_title3: '深入洞察市场',
      sub_title4: '优化营销策略',
      sub_title5: '赋能企业内部',
      sub_title6: '隐私与安全',
      sub_title7: '专属服务团队',
      sub_dec1: '我们在你需要的任何地方。Worldspire是一家全球性公司，拥有便利的地理环境、优秀的员工以及坚实的技术实力，致力于在不同地区为客户提供数据相关服务。',
      sub_dec2: '我们的平台支持实时收集、处理、分析、丰富和激活客户数据。随着客户需求和偏好的变化，您的企业需要变得更加敏捷，从而为客户提供更极致的体验。',
      sub_dec3: '我们可以帮助您更好地了解消费者在线的行为方式，实现精准的消费者洞察，从而科学地改善客户体验。',
      sub_dec4: '当下，对于企业而言整体数字营销策略比以往任何时候都重要。通过数据，企业可以了解目标受众对于营销活动及策略的反馈，并由此创造更有效、更个性化的广告。',
      sub_dec5: 'Worldspire助力企业的所有团队收集、理解并激活他们的数据。',
      sub_dec6: "数据安全与客户隐私始终是Worldspire发展经营的核心关键。目前我们已经获得ISO 27001、ISO  9001认证，并符合GDPR、CCPA、LGPD及HIPAA认证标准。",
      sub_dec7: '我们拥有丰富从业经验的客户成功团队与数字营销专家，以帮助您的企业改善客户体验并实现业务目标。',
    }
  },
  company: {
    about: {
      banner_title: '关于我们',
      text1: '以AI 和数据科学为核心，打造下一代智能商业应用',
      text2: 'Worldspire是一家数据技术和AI公司，致力于差异化地为中大型B2C企业提供AI驱动的客户数据技术解决方案，助力企业优化与客户数据相关的应用场景，包括客户洞察、个性化营销、客户生命周期价值管理，客户体验提升等。创略科技服务上百家中型国内外公司，客户覆盖零售、汽车、金融、旅游和教育培训等行业。', 
      text3: 'We combine cutting-edge big data and AI to tackle problems in areas of marketing, personalization, and customer analytics.',
      text4: 'We have served 300+ mid to large global and China enterprises in sectors of retail, automotive & mobility, travel, finance and ecommerce.',
      awards_title: '权威奖项',
      awards_dec1: '2018 红鲱鱼全球100强大数据领域唯一上榜中国公司',
      awards_dec2: '入选2018福布斯潜力企业榜单50强',
      awards_dec3: '2018中国大数据行业独角兽50强',
      awards_dec4: '2018 人工智能企业百强',
      awards_dec5: '从1500+项目中突围入选腾讯AI加速器三期企业',
      awards_dec6: '从1000家企业中脱颖而出的入选微软加速器的15家公司之一',
      certification_title: 'ISO9001认证及高新企业认证',
      certification_dec1: '质量管理体系认证',
      certification_dec2: '国家级高新技术企业',
      patents_title: '50+产品相关计算机软件著作及专利',
      patents_item1: '企业大数据加密软件',
      patents_item2: '客户标签分析软件',
      patents_item3: 'Worldspire机器学习服务软件',
      patents_item4: '客户数据管理平台软件（CDP）',
      patents_item5: '大数据报表管理软件',
      patents_item6: 'Worldspire中国数字营销平台软件',
      patents_item7: '数据分析漏斗软件',
      patents_item8: '企业级智能数据平台',
      patents_item9: 'Worldspire API网管软件',
      patents_item10: '用户营销透镜分析软件',
      patents_item11: 'Worldspire代码管理系统',
      patents_item12: 'Worldspire A/B测试优化软件',
      city1: '上海',
      city2: '纽约',
      city3: '旧金山',
      city4: '亚特兰大',
      city5: '首尔',
      city6: '伦敦',
      city7: '巴塞罗那',
      city8: '东京',
      // citys:[
      //   'SHANGHAI', 'NEW YORK', 'SAN FRANCISCO', 'ATLANTA', 'SEOUL', 'LONDON', 'BARCELONA', 'TOKYO'
      // ],
      customer: '我们的客户',
      org_title1: "Worldspire严格遵守隐私法律和规范",
      org_title2: "",
      org1: "CCPA",
      org2: "GDPR",
      org3: "HIPAA",
      org4: "LGPD",
    },
    contact: {
      banner_title: '联系我们',
      city1: '香港总部',
      city2: '深圳',
      city3: '北京',
      city4: 'Silicon Valley',
      city5: '上海',
      city6: '首尔',
      addr1: '香港金钟金钟道89号力宝中心第一座10楼1003室',
      email1: '邮箱：',
      addr2: '深圳市福田区皇岗路5001号 深业上城南区LOFTB 6楼',
      addr3: '北京市朝阳区亮马桥东方东路19号外交办公大楼D座17层',
      addr4: '111 Deerwood Rd Suite 200 San Ramon, CA 94583',
      // email2: '邮箱: sf@chinapex.com',
      addr5: '上海市虹口区东大名路1098号浦江国际金融广场902-908室',
      addr6: '首尔市西大门区延熙路41街57号KH 101室',
    },
    partners: {
      banner_title: "合作伙伴",
      parteners_title: "我们的合作伙伴",
      parteners_dec: "我们与16+主流海外数字营销平台以及电商渠道合作，为您提供更精确和可靠的数据服务。",
      join_title: "Join Worldspires's partner",
      join_dec: "Sign up below and we will contact you shortly with more information about our solution partner program.",
      join_btn: "Become a Partner"
    },
    blog: {
      banner_title: "Blog"
    },
    news: {
      banner_title: "新闻及传媒",
      read_more: "更多",
      list: {
        item1: {
          title: "ROI提升“一把好手”，跨渠道数据交换平台（CDS）重磅发布！",
          content: "随着出海市场阶段性流量高峰的回落，行业竞争愈发激烈。数字化营销成为关乎声量更关乎销量的关键布局，然而在这一过程中，数据资产的管理，全局性把控，有效性分析成为难题。",
        }
      }
    },
    news_detail: {
      banner_title: "新闻及传媒",
      detail1: {
        title: "跨渠道数据交换平台（CDS）重磅发布",
        date: "2022-4-1"
      }
    }
  },
  solutions: {
    retail: {
      banner_title: '零售行业',
      sub_title1: '挑战',
      sub_dec1: '在不断变化的消费者需求与技术趋势下，如何打造顾客喜爱的全渠道购物体验成为零售品牌的巨大挑战。同时，不断完善的数据安全条例也正在加剧这一挑战，品牌不仅很难取悦买家，也难以提升运营效率及灵活度以实现增长。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '为了给予消费者与众不同的购物体验，品牌必须通过数据创造完整的客户画像，全方位洞察消费者，并实时用于营销策略如个性化推送等，才能以最大程度提升转化率。',
      sub_title3: "我们如何赋能？",
      sub_dec3: '构建完整的用户画像。Worldspire基于强大的数据底层处理能力，将割裂的数据整合并打通，帮助零售企业构建统一、可用的数据基础，并基于洞察形成360度客户画像以指导客户体验等企业全方位经营策略。'
    },
    game: {
      banner_title: '游戏行业',
      sub_title1: '挑战',
      sub_dec1: '电子游戏行业市场在过去十五年中发展迅速，已经从一种休闲娱乐活动发展成为一个巨大的蓝海市场，在全球范围内影响深远。急速的扩展使得众多创业者涌入，电子游戏行业竞争进入白热化阶段。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '在激烈的行业竞争下，吸引新用户极其具有挑战，十分考验游戏的开发水平以及对于玩家兴趣喜好的精准洞察。',
      sub_title3: '我们如何赋能？',
      sub_dec3: 'Worldspire帮助游戏公司分析角色创建、游戏教学、应用购买等多场景数据，以此为洞察为玩家创造更丰富的体验。同时，Worldspire支持多游戏数据打通服务，通过交叉分析特定的游戏数据，使公司更了解玩家的游戏偏好，并基于此规划游戏场景及营销活动，为玩家提供更个性、更深入的游戏体验。',
    },
    financial: {
      banner_title: '金融服务行业',
      sub_title1: '挑战',
      sub_dec1: '数字化时代，体验成为金融机构的差异化竞争优势。然而，随着客户需求逐渐个性化、多样化、场景化，金融机构难以准确识别并进行服务和营销，从而实现增长。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '通过收集和应用数据，金融机构为客户提供更个性化的数字体验，以值得信赖的专家顾问形象，与客户建立起更深度链接。',
      sub_title3: '我们如何赋能？',
      sub_dec3: '沉淀并激活客户数据资产，可以使金融服务机构改善互动并为客户提供极致的体验。目前，超过10家领先的金融服务和顶级支付服务企业选择Worldspire，通过客户数据收集、清理、激活，实现全面的客户洞察并赋能于客户体验提升。',
    },
    media: {
      banner_title: '媒体行业',
      sub_title1: '挑战',
      sub_dec1: '用户每天在不同设备上与媒体打交道，平均最多可达12小时。如此高强度的连接让用户期望拥有更完整的体验。为此，媒体亟需识别不同设备上的用户，并由此创造无缝的体验。这对媒体公司来说，尤其是希望通过移动和OTT渠道吸引客户的公司，既是机会也是挑战。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '由于用户频繁在多种设备和渠道上转换，媒体无法再基于已有系统平台去了解用户是谁，以及如何何时触达，并难以衡量营销、产品以及系统之间的关系从而制定有效的营销增长策略。为此，更多具有前瞻性思维的媒体已经开始采用客户数据平台以统一并激活用户数据。',
      sub_title3: '我们如何赋能？',
      sub_dec3: '找到正确的CDP，营销人员需要考虑他们目前的数据成熟度以及它如何被使用以及他们的未来目标是什么以及需要什么数据来实现这些目标。数据成熟度级别可以分为四个成熟度级别，从最低到最成熟：基础、洞察力和实验、全渠道参与和持续优化。本博客将带您浏览各个级别的用例，帮助您确定哪些CDP功能与您的情况相关，并找到将帮助您满足当前和未来业务需求的CDP。',
    },
    travel: {
      banner_title: '旅游行业',
      sub_title1: '挑战',
      sub_dec1: '疫情黑天鹅的影响下，旅游业急速停摆。为了加速行业复苏，酒店、航司等企业不得不改变营销策略，与客户加深连接以求活下去并活的更好。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '当下旅客更关注于旅行体验。为此，良好且持续的体验将推动旅游企业转化率、忠诚度及交叉销售的提升，赋能可持续增长。',
      sub_title3: '我们如何赋能？',
      sub_dec3: '卓越的客户体验需要基于数据来实现。Worldspire帮助酒店、航司等企业实时收集、整合、打通并激活数据，形成全方位的客户洞察，从而赋能客户体验提升。',
    },
    automotive: {
      banner_title: '汽车行业',
      sub_title1: '挑战',
      sub_dec1: '车企拥有充足的资金，以及来自经销商、自有APP、官网、广告、营销工具等多渠道的丰富数据。但数据散落在各个系统中，车企往往只能基于经验或有限的数据制定营销策略。',
      sub_title2: '为什么这很重要？',
      sub_dec2: '为了提升营销ROI，车企需要收集一方客户数据并将其与三方数据整合，以整体的消费者视图驱动个性化客户体验。但由于数据散落在不同渠道、不同部门、不同系统，缺乏有效的串联机制和统一识别算法来进行统一管理与分析，造成数据孤岛。',
      sub_title3: '我们如何赋能？',
      sub_dec3: 'Worldspire CDP为车企提供了可打通不同数据源，即插即用、快速实施的解决方案，使数据可以在不同品牌下流转，赋能营销效率效果提升。通过CDP解决方案，某车企客户洞察出大概率决定购买的客户人群，并优化这些客户的体验，以提高转化率。其基于行为创建了不同受众群体，并提供个性化沟通，从而推动转化率、收入增长和更具转化效益的广告投放。统一的客户数据和精准的预测建模提高了成交率和营销ROI，帮助车企降低成本，并进一步加强了营销和销售团队之间的协作和沟通。目前，该客户在不断拓展数据的应用场景与价值。其利用售后数据洞察客户需求反馈并设计新产品与服务，以提高收入并巩固客户品牌忠诚度。数字化对于该车企最大的改变在于：当它拥有360度客户画像后，客户体验的可能性是无穷的。 ',
    }
  },
  pricing: {
    banner_title: "定制属于您的方案",
    banner_text: "根据您的需求选择方案",
    free: {
      title: "免费",
      btn: "创建免费账户",
      price: "$0/月",
      sub_title: "方案包含", 
      text1: "1000月活用户",
      text2: "2个渠道",
      text3: "300+ Integrations",
    },
    team: {
      title: "团队",
      btn: "免费试用",
      price: "$80/月起",
      sub_title: "所有免费方案内容加", 
      text1: "100,000月活用户",
      text2: "不限渠道",
      text3: "一个数据库",
    },
    business: {
      title: "企业",
      btn: "联系我们",
      price: "定制价格",
      sub_title: "所有团队方案内容加", 
      text1: "定制容量",
      text2: "客户唯一视图",
      text3: "数据治理",
      text4: "高级账号角色权限",
      text5: "历史回放",
    },
    compare_plans: '比较方案'
  }
};

export default zhCN;
