<template>
    <keep-alive>
        <router-view />
    </keep-alive>
</template>
<script>
export default {
    name: 'PageRoute'
}
</script>
