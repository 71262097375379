import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import setLang from "@/language/index";
import "Assets/font/font.less"
import "Assets/less/base.less";
import { v4 as uuidv4 } from 'uuid';

if (!localStorage.getItem('userId')) {
    let userId = uuidv4();
    localStorage.setItem('userId', userId)
}

createApp(App).use(router).use(setLang).mount("#app");
router.afterEach(() => {
    document.documentElement.scrollTop = 0
})
