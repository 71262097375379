import { createI18n } from "vue-i18n";
import enUS from "./en";
import zhCN from "./zh";
import Kr from "./kr"

function setLang(app) {
  const i18n = createI18n({
    legacy: false,
    locale: "en-US",
    messages: {
      "zh-CN": zhCN,
      "en-US": enUS,
      "kr": Kr
    },
  });
  app.use(i18n);
}

export default setLang;
